import React, { Component } from "react";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import NatalChart from "./Natal_chart";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { GlobalContext } from "../../../mycontext";
import Sideform from "../../SideComponents/sideform";
import ResetButton from "../ResetButton/ResetButton";
import DivisionalTable from "./DivisionalTable";
import { withTranslation } from "react-i18next";
import ImageMapper from "../ImageMapper.json";
import {getQueryParam} from '../../utils/utility';

const styles = (theme) => ({
  divisionalChartDiv: {
    position: "relative",
    background: "#fff",
    minHeight: "600px",
  },
  natalChartdiv: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const valueMapper = {
  1: "Hora Wealth (D2 Chart)",
  2: "Drekkana-Siblings (D3 Chart)",
  3: "Chaturthamsha-Luck (D4 Chart)",
  4: "Saptamamsha-Children (D7 Chart)",
  5: "Navamsha-Spouse (D9 Chart)",
  6: "Dashamamsha-Profession (D10 Chart)",
  7: "Dwadashmamsha-Parents (D12 Chart)",
  8: "Shodashamsha-Vehicles (D16 Chart)",
  9: "Vimshamsha-Religious Inclinations(D20 Chart)",
  10: "Chaturvimshamsha-Education (D24 Chart)",
  11: "Saptavimshamsha-Strength (D27 Chart)",
  12: "Trimshamsha-Misfortune (D30 Chart)",
  13: "Khavedamsha-Auspicious Results(D40 Chart)",
  14: "Akshvedamsha-Well Being (D45 Chart)",
  15: "Shastiamsha-Well Being (D60 Chart)",
};

const valueOptions = [
  { value: 1, label: "D2" },
  { value: 2, label: "D3" },
  { value: 3, label: "D4" },
  { value: 4, label: "D7" },
  { value: 5, label: "D9" },
  { value: 6, label: "D10" },
  { value: 7, label: "D12" },
  { value: 8, label: "D16" },
  { value: 9, label: "D20" },
  { value: 10, label: "D24" },
  { value: 11, label: "D27" },
  { value: 12, label: "D30" },
  { value: 13, label: "D40" },
  { value: 14, label: "D45" },
  { value: 15, label: "D60" },
];

class DivisionalChart extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      value: "5",
      tab: "0",
      selectedOption: { value: 5, label: "D9" },
      horoscopeData:
        JSON.parse(window.localStorage.getItem("horoscopeData")) || null,
      tableData: {},
      ausData: {},

      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    let googleAuthObj = JSON.parse(
      window.localStorage.getItem("googleAuthObj")
    );
    if (googleAuthObj !== null && this.context.googleAuthObj === null) {
      this.context.setGoogleAuthObj(googleAuthObj);
    }
    let queryString = getQueryParam('cb');
    if(queryString){
      // alert(queryString);
       this.setState({cb_view:true});
       this.setState({cb_horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
       this.setState({cb_requestParamsHoroscope:JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`))});
 
       this.setState({horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
       this.setState({requestParamsHoroscope:JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`))});
     }
     else{

    let requestParams = JSON.parse(
      window.localStorage.getItem("requestParamsHoroscope")
    );
    if (
      requestParams !== null &&
      Object.keys(this.context.requestParams).length === 0
    ) {
      this.context.setRequestParamsHoroscope(requestParams);
    }
    if (this.state.horoscopeData === null) {
      this.context.handleHoroscopeForm(true, "open");
    } else {
      this.context.setHoroscopeData(this.state.horoscopeData);
      this.filterData(this.state.horoscopeData, this.state.tab);
    }
  }
  }

  componentDidUpdate(prevProps) {

    if (this.props.horoscopeData !== prevProps.horoscopeData) {
      this.setState({ horoscopeData: this.context.horoscopeData });
      this.filterData(this.context.horoscopeData, this.state.tab);
    } else if (this.props.t !== prevProps.t) {
      this.convertToLanguageMultiple(valueOptions, this.props.t);
      this.convertToLanguageSingle(this.state.selectedOption, this.props.t);
    }
  }

  filterData(horoscopeData, type) {
    if (type !== "0" && type !== "3") {
      let list = [1, 2, 3, 4, 7, 9, 10, 12, 16, 20, 24, 27, 30, 40, 45, 60];
      let planetName = [
        "Asc",
        "Sun",
        "Moon",
        "Mars",
        "Merc",
        "Jupt",
        "Venu",
        "Satn",
        "Rahu",
        "Ketu",
      ];
      let Mapper = {
        1: "rashi_map_for_planets",
        2: "house_map_for_planets",
      };
      let mainjson = [];
      let requiredData = horoscopeData[Mapper[type]];
      for (let i in requiredData) {
        let json = {};
        json["num"] = list[i];
        for (let j in requiredData[i]) {
          json[planetName[j]] = requiredData[i][j];
        }
        mainjson.push(json);
      }
      this.setState({ tableData: mainjson });
    } else if (type === "3") {
      this.setState({
        ausData: horoscopeData.auspicious_inauspicious_placing,
      });
    }
  }

  handleChange(k) {
    this.setState({ tab: k }, () =>
      (this.state.cb_view) ?this.filterData(this.state.cb_horoscopeData, k) : 
      this.filterData(this.context.horoscopeData, k)
    );
  }

  // convertToLanguageMultiple = (obj) => {
  //   obj.map((i, value) => {
  //     i["label"] = this.props.t(i["label"]);
  //     return i;
  //   });
  //   return obj;
  // };
  // convertToLanguageSingle = (obj) => {
  //   obj["label"] = this.props.t(obj["label"]);
  //   return obj;
  // };

  convertToLanguageMultiple = (obj, t) => {
   
    if (obj !== null) {
      let obj2 = JSON.parse(JSON.stringify(obj));
      obj2.map((i, value) => {
        
        i["label"] = t(i["label"]);
        i["value"] = t(i["value"]);
        this.state.selectedOption.value && this.convertToLanguageSingle(i,t)
        return i;
      });
    
      return obj2;
    }

  };
  convertToLanguageSingle = (obj, t) => {
    // console.log(obj);
    let obj2 = JSON.parse(JSON.stringify(obj));
   
    obj2["label"] = t(obj2["label"]);
    obj2["value"] = t(obj2["value"]);
    
    
    return obj2;
  };

  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    if ( this.state.cb_view || (
      Object.keys(this.context.horoscopeData).length > 0 &&
      typeof this.context.horoscopeData !== "undefined")
    ) {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"divisionalCharts"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
          <div className={[classes.divisionalChartDiv, "px-1"].join(" ")}>
            <ResetButton />
            <div className="panel with-nav-tabs panel-warning">
              <div className="panel-heading">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={this.state.tab}
                  onSelect={(k) => this.handleChange(k)}
                >
                  <Tab
                    disabled={this.state.tab === "0" ? true : false}
                    className={classes.tab}
                    eventKey={0}
                    title={t("Charts")}
                  ></Tab>
                  <Tab
                    className={classes.tab}
                    eventKey={1}
                    title={t("Planet to Rashi")}
                  ></Tab>
                  <Tab
                    className={classes.tab}
                    eventKey={2}
                    title={t("Planet to House")}
                  ></Tab>
                  <Tab
                    className={classes.tab}
                    eventKey={3}
                    title={t("Auspicious, InAuspicious Placing")}
                  ></Tab>
                </Tabs>
              </div>
              {this.state.tab === "0" ? (
                <div className="">
                  <div
                    className="row"
                    style={{ paddingTop: "10px", paddingBottom: "20px" }}
                  >
                    <label className="basic-single-label d-flex">
                      <span
                        className="dasha-name"
                        style={{ paddingRight: "10px", paddingTop: "10px" }}
                      >
                        {" "}
                        {t("Select Chart")}:{" "}
                      </span>
                      <span style={{ width: "120px" }}>
                        {/* <Select
                          value={this.convertToLanguageSingle(
                            this.state.selectedOption,
                            t
                          )}
                          onChange={(e) =>
                            this.setState({ value: e.value, selectedOption:e })
                          }
                          options={this.convertToLanguageMultiple(
                            valueOptions,
                            t
                          )}
                        /> */}
                       
                        <select className="form-control"
                        defaultValue={this.state.selectedOption.value}
                        onChange={(e)=>{
                          console.log(e.target.value)
                          this.setState({value:e.target.value,
                                        selectedOption:{value:e.target.value,label:e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text}})
                                       }}
                        
                        >
                          {
                            valueOptions.length>0 && valueOptions.map((e,i)=>(
                              <option key={i} value={e.value}>{t(e.label)}</option>
                            ))
                          }
                             
                        </select>
                      </span>{" "}
                    </label>
                  </div>

                  <div className="row">
                    {this.state.value !== "0" &&
                    this.state.horoscopeData !== null ? (
                      <>
                        <NatalChart
                          Chartname={t("Lagna Chart (D1)")}
                          data={
                            this.state.horoscopeData.divisional_charts[0]
                              .list_of_houses
                          }
                          // data={[
                          //   {
                          //     "house_num": 1,
                          //     "rashi_name": "Gemini",
                          //     "rashi_num": 3,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   },
                          //   {
                          //     "house_num": 2,
                          //     "rashi_name": "Cancer",
                          //     "rashi_num": 4,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   },
                          //   {
                          //     "house_num": 3,
                          //     "rashi_name": "Leo",
                          //     "rashi_num": 5,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   },
                          //   {
                          //     "house_num": 4,
                          //     "rashi_name": "Virgo",
                          //     "rashi_num": 6,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   },
                          //   {
                          //     "house_num": 5,
                          //     "rashi_name": "Libra",
                          //     "rashi_num": 7,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   },
                          //   {
                          //     "house_num": 6,
                          //     "rashi_name": "Scorpio",
                          //     "rashi_num": 8,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   },
                          //   {
                          //     "house_num": 7,
                          //     "rashi_name": "Sagittarius",
                          //     "rashi_num": 9,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   },
                          //   {
                          //     "house_num": 8,
                          //     "rashi_name": "Capricorn",
                          //     "rashi_num": 10,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   },
                          //   {
                          //     "house_num": 9,
                          //     "rashi_name": "Aquarius",
                          //     "rashi_num": 11,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   },
                          //   {
                          //     "house_num": 10,
                          //     "rashi_name": "Pisces",
                          //     "rashi_num": 12,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   },
                          //   {
                          //     "house_num": 11,
                          //     "rashi_name": "Aries",
                          //     "rashi_num": 1,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   },
                          //   {
                          //     "house_num": 12,
                          //     "rashi_name": "Taurus",
                          //     "rashi_num": 2,
                          //     "list_of_planets": [
                          //       "Su(R)",
                          //       "Mo(R)",
                          //       "Ma(R)",
                          //       "Me(R)",
                          //       "Ju(R)",
                          //       "Ve(R)",
                          //       "Sa(R)",
                          //       "Ra(R)"
                          //     ]
                          //   }
                          // ]}
                          className="svg1"
                          id="container1"
                          Asc={t("Asc")}
                        />
                        <div className="imageDiv">
                          <img
                            className="image-center"
                            alt="Div"
                            height="100vh"
                            width="100vw"
                            src={
                              this.state.selectedOption.label !== "D20" &&
                              ImageMapper[this.state.selectedOption.label] !==
                                "D20"
                                ? `/assets/div-icons/${
                                    ImageMapper[this.state.selectedOption.label]
                                  }.svg`
                                : `/assets/div-icons/${
                                    ImageMapper[this.state.selectedOption.label]
                                  }.png`
                            }
                          ></img>
                        </div>
                        <NatalChart
                          Chartname={t(valueMapper[this.state.value])}
                          data={
                            this.state.horoscopeData.divisional_charts[
                              this.state.value
                            ].list_of_houses
                          }
                          className="svg2"
                          id="container2"
                          Asc={t("Asc")}
                        />
                      </>
                    ) : this.state.horoscopeData !== null ? (
                      <NatalChart
                        Chartname={t("Lagna Chart (D1)")}
                        data={
                          this.state.horoscopeData.divisional_charts[0]
                            .list_of_houses
                        }
                        className="svg1"
                        id="container1"
                        Asc={t("Asc")}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ) : (this.state.tab === "1" || this.state.tab === "2") &&
                Object.keys(this.state.tableData).length > 0 ? (
                <DivisionalTable
                  data={this.state.tableData}
                  tab={this.state.tab}
                />
              ) : this.state.tab === "3" &&
                Object.keys(this.state.ausData).length > 0 ? (
                <DivisionalTable
                  ausData={this.state.ausData}
                  tab={this.state.tab}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"divisionalCharts"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
        </>
      );
    }
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(DivisionalChart)
);
