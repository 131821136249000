import React, { Component } from "react";
import "./CView.css";
import { GlobalContext } from "../../../mycontext";
// import NatalChart from "./Natal_chart";
import NatalChartsD1 from "../NatalCharts/NatalChartsD1";
import NatalChartAstak from "../AstakVarga/Natal_chart";
import BarGraph from "./Bargraph";
import TableCView from "./TableCView";
import Api from "../../../API/Api";
import NatalChartTrans from "./NatalChartTrans";
import NatalChartMoon from "../NatalCharts/NatalChartMoon";
import NatalChartKundali from "../NatalCharts/NatalChartKundali";
import PlanetD3Reation from "../PlanetRelationships/Planet-d3-realtion";
import { withTranslation } from "react-i18next";
import NatalChartLuminary from "../PlanetPositions/NatalChartLuminary";
import NatalChartAsc from "../PlanetPositions/NatalChartAsc";
function pad(n) {
  return n < 10 ? "0" + n : n;
}

const planetMapper = {
  20: "Sun",
  21: "Moon",
  22: "Mars",
  23: "Mercury",
  24: "Jupiter",
  25: "Venus",
  26: "Saturn",
  27: "Asc",
};

// const NatalChartMapper = {
//   dhoom_rdms: "Dhoom",
//   vyatipaath_rdms: "Vyatipaath",
//   parivesh_rdms: "Parivesh",
//   indrachaap_rdms: "Indrachaap",
//   upketu_rdms: "Upketu",
//   bhavalagna: "Bhava Lagna",
//   horalagna: "Hora Lagna",
//   ghatilagna: "Ghati Lagna",
//   varnad: "Varnad",
//   pranpada: "Pran Pada",
// };
const NatalChartMapper = {
  dhoom_rdms: "Dhoom",
  vyatipaath_rdms: "Vyatipaath",
  parivesh_rdms: "Parivesh",
  indrachaap_rdms: "Indrachaap",
  indrachap: "Indra Chap",
  upketu_rdms: "Upketu",
  bhavalagna: "Bhava Lagna",
  horalagna: "Hora Lagna",
  ghatilagna: "Ghati Lagna",
  varnad: "Varnad",
  pranpada: "Pran Pada",
  kaallagna: "Kaal Lagna",
  yamghant: "Yamghant",
  paridi: "Paridi",
  ardhprahar: "Ardhprahar",
  gulik: "Gulik",
  mrityulagna: "Mrityu Lagna",
  pranpada: "Pran Pada"
};

const RashiMapper = {
  1: "Ari",
  2: "Tau",
  3: "Gem",
  4: "Can",
  5: "Leo",
  6: "Vir",
  7: "Lib",
  8: "Sco",
  9: "Sag",
  10: "Cap",
  11: "Aqu",
  12: "Pis",
};

let calculateHouseNumber = (rashi_num, asc_num) => {
  let house = rashi_num - asc_num + 1;
  if (house <= 0) {
    house = house + 12;
  }
  return house;
};

let LuminaryRashiNum = (data, val) => {
  if (typeof data !== "undefined") {
    if (typeof data[val] === "object") {
      if (data[val][1] || data[val][2] || data[val][3] > 0) {
        return data[val][0] + 1;
      } else {
        return data[val][0];
      }
    }
  }
};

let LuminaryRashiNumAsc = (data, val) => {
  if (typeof data !== "undefined" && typeof data[val] !== "string") {
    if (data[val][1] || data[val][2] || data[val][3] > 0) {
      return data[val][0] + 1;
    } else {
      return data[val][0];
    }
  }
};
class SectionComponent extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      astakData: null,
      fivefoldData: null,
      horoTransit: null,
    };
  }

  componentDidMount() {
    if (this.props.value > 16 && this.props.value <= 27) {
      this.filterAstakData(this.props.horoscopeData, this.props.value);
    } else if (this.props.value === 30) {
      this.filterFiveFold(this.props.horoscopeData);
    } else if (this.props.value === 32 || this.props.value === 33) {
      this.filterHoroTransit(
        this.props.horoscopeData,
        this.props.requestParamsHoro,
        this.props.value
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.horoscopeData !== prevProps.horoscopeData ||
      this.props.value !== prevProps.value ||
      this.props.requestParamsHoro !== prevProps.requestParamsHoro
    ) {
      if (this.props.value > 16 && this.props.value <= 27) {
        this.filterAstakData(this.props.horoscopeData, this.props.value);
      } else if (this.props.value === 30) {
        this.filterFiveFold(this.props.horoscopeData);
      } else if (this.props.value === 32 || this.props.value === 33) {
        this.filterHoroTransit(
          this.props.horoscopeData,
          this.props.requestParamsHoro,
          this.props.value
        );
      }
    }
  }

  filterAstakData(horoscopeData, value) {
    if (value >= 20 && value <= 27) {
      let requiredData = horoscopeData.ashtak_varga.bhinnashtak_vargas;
      let mainjson = [];
      let finalData = requiredData[planetMapper[value]]["Net"];
      let count = 1;
      for (let i in finalData) {
        if (count > 12) {
          break;
        }
        let json = {};
        json["rashi_num"] = count;
        json["list"] = finalData[i];
        mainjson.push(json);
        count = count + 1;
      }
      // mainjson.unshift(mainjson.pop());
      // mainjson.unshift(mainjson.pop());
      // let x = horoscopeData.divisional_charts[0].list_of_houses[0].rashi_num;
      // let first_x_elements = mainjson.slice(0, x + 1);
      // let remaining_elements = mainjson.slice(x + 1, 12);

      let x = horoscopeData.divisional_charts[0].list_of_houses[0].rashi_num;
     x<12 && mainjson.unshift(mainjson.pop());
     x<12 && mainjson.unshift(mainjson.pop());
     let first_x_elements=x<12 ? mainjson.slice(0, x + 1):mainjson.slice(0,x-1);
     let remaining_elements=x<12 ? mainjson.slice(x + 1, 12):mainjson.slice(x-1,12);

      // Destructuring to create the desired array
      let mainArr = [...remaining_elements, ...first_x_elements];
      this.setState({ astakData: mainArr });
    } else {
      const sarvMapper = {
        17: "samudaya_ashtak_varga",
        18: "samudaya_ashtak_varga_after_trikona_reduction",
        19: "samudaya_ashtak_varga_after_EK_reduction",
      };
      let data = horoscopeData.ashtak_varga;
      let planetData = data[sarvMapper[value]];
      let mainjson = [];
      let keys = Object.keys(planetData);
      for (let i in keys) {
        let json = {};
        json = planetData[keys[i]];
        json["Main Planet"] = keys[i];
        mainjson.push(json);
      }
      this.setState({ tableData: mainjson });
      var reqChartData = mainjson[7];
      var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      const mainChart = [];
      for (let i of arr) {
        const res = {};
        res["rashi_num"] = i;
        res["list"] = reqChartData[RashiMapper[i]];
        mainChart.push(res);
      }
      // mainChart.unshift(mainChart.pop());
      // mainChart.unshift(mainChart.pop());
      // let x = horoscopeData.divisional_charts[0].list_of_houses[0].rashi_num;
      // let first_x_elements = mainChart.slice(0, x + 1);
      // let remaining_elements = mainChart.slice(x + 1, 12);

      let x = horoscopeData.divisional_charts[0].list_of_houses[0].rashi_num;
     x<12 && mainChart.unshift(mainChart.pop());
     x<12 && mainChart.unshift(mainChart.pop());
     let first_x_elements=x<12 ? mainChart.slice(0, x + 1):mainChart.slice(0,x-1);
     let remaining_elements=x<12 ? mainChart.slice(x + 1, 12):mainChart.slice(x-1,12);

      // Destructuring to create the desired array
      let mainArr = [...remaining_elements, ...first_x_elements];
      this.setState({ astakData: mainArr });
    }
  }

  filterFiveFold(horoscopeData) {
    let planetData = horoscopeData.planetary_relationship;
    let requiredData = planetData["five_fold_relations"];
    let mainjson = [];
    for (let i of requiredData) {
      let json = {};
      json["Main Planet"] = i.main_planet_name;
      for (let j of i.relation_with_others) {
        json[j.planet_name] = j.relationship_value;
      }
      mainjson.push(json);
    }
    this.setState((state) => {
      return {
        fivefoldData: mainjson,
      };
    });
  }

  filterHoroTransit(data, params, value) {
    const date = new Date();
    const time =
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds());
    let API = new Api();

    let splitTime = time.split(":");
    let option =
      this.props.value === 32
        ? data.divisional_charts[0].list_of_houses[0].rashi_num
        : data.moon_chart.list_of_houses[0].rashi_num;
    API.fetchHoroTransit(
      date.getDate(),
      date.getMonth() + 1,
      date.getFullYear(),
      splitTime[0],
      splitTime[1],
      splitTime[2].slice(0, 2),
      params.latitude,
      params.longitude,
      params.offset,
      params.name,
      params.state,
      params.country,
      params.reference_longitude_for_timezone,
      params.timezone,
      option,
      localStorage.getItem("omp_token")
    )
      .then((result) => {
        this.setState({ horoTransit: result.data });
      })
      .catch((e) => {
        // console.log(e);
      });
  }

  ModifyToChartDataLuminary = (data, t) => {
    if (data !== "undefined") {
      let json = [];
      let RashiNumberMapper = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      };

      for (let val of Object.keys(data)) {
        let rashiNum = LuminaryRashiNum(data, val);
        RashiNumberMapper[rashiNum] = [
          ...RashiNumberMapper[rashiNum],
          t(NatalChartMapper[val]),
        ];
      }
      let ascNum =
        this.props.horoscopeData.natal_chart.list_of_houses[0].rashi_num;
      for (let i = ascNum; i <= 12; i++) {
        let innerjson = {};
        innerjson.rashi = i;
        innerjson.planets = RashiNumberMapper[i];
        innerjson.house = i;
        json.push(innerjson);
      }
      for (let i = 1; i < ascNum; i++) {
        let innerjson = {};
        innerjson.rashi = i;
        innerjson.planets = RashiNumberMapper[i];
        innerjson.house = i;
        json.push(innerjson);
      }

      return json;
    }
  };

  ModifyToChartDataAsc = (data, t) => {
    if (data !== "undefined") {
      let json = [];
      let RashiNumberMapper = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      };

      for (let val of Object.keys(data)) {
        let rashiNum = LuminaryRashiNumAsc(data, val);
        if (!isNaN(rashiNum)) {
          // RashiNumberMapper[rashiNum] = [
          //   ...RashiNumberMapper[rashiNum],
          //   t(NatalChartMapper[val]),
          // ];
          if (Array.isArray(RashiNumberMapper[rashiNum])) {
            const newValue = t(NatalChartMapper[val]);
            if (newValue !== undefined && newValue !== null) {
                RashiNumberMapper[rashiNum] = [
                    ...RashiNumberMapper[rashiNum],
                    newValue
                ];
            }
        } else {
            // console.log("inside Elese");
            // console.log(RashiNumberMapper[rashiNum]);
            RashiNumberMapper[rashiNum] = [];
        }
        }
      }
      let ascNum =
        this.props.horoscopeData.natal_chart.list_of_houses[0].rashi_num;
      for (let i = ascNum; i <= 12; i++) {
        let innerjson = {};
        innerjson.rashi = i;
        innerjson.planets = RashiNumberMapper[i];
        innerjson.house = i;
        json.push(innerjson);
      }
      for (let i = 1; i < ascNum; i++) {
        let innerjson = {};
        innerjson.rashi = i;
        innerjson.planets = RashiNumberMapper[i];
        innerjson.house = i;
        json.push(innerjson);
      }

      return json;
    }
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        {this.props.value === 0 ? (
          <NatalChartMoon
            data={this.props.horoscopeData.moon_chart.list_of_houses}
            className={`svg-${this.props.section}`}
            id={`container-${this.props.section}`}
            w="400"
            h="450"
            xview="340"
            yview="215"
            parent="cview"
            t={t}
            Asc={t("Asc")}
          />
        ) : this.props.value >= 1 && this.props.value <= 16 ? (
          <NatalChartsD1
            data={
              this.props.horoscopeData.divisional_charts[this.props.value - 1]
                .list_of_houses
            }
            className={`svg-${this.props.section}`}
            id={`container-${this.props.section}`}
            name="divcharts"
            w="400"
            h="450"
            xview="340"
            yview="215"
            t={t}
            Asc={t("Asc")}
          />
        ) : this.props.value > 16 &&
          this.props.value <= 27 &&
          this.state.astakData !== null ? (
          <NatalChartAstak
            className={`svg-${this.props.section}`}
            id={`container-${this.props.section}`}
            data={this.state.astakData}
            rightMargin=""
            w="400"
            h="450"
            xview="340"
            yview="215"
            parent="cview"
            t={t}
          />
        ) : this.props.value > 27 && this.props.value <= 29 ? (
          <BarGraph
            horoscopeData={this.props.horoscopeData}
            barValue={this.props.value}
            id={`container-${this.props.section}`}
            t={t}
          />
        ) : this.props.value === 30 && this.state.fivefoldData !== null ? (
          <PlanetD3Reation
            height={400}
            width={375}
            radius={30}
            horoscopeData={this.props.horoscopeData}
            value={3}
            planets={[
              t("SU"),
              t("MO"),
              t("MA"),
              t("ME"),
              t("JU"),
              t("VE"),
              t("SA"),
            ]}
          />
        ) : this.props.value === 31 ? (
          <TableCView
            horoscopeData={this.props.horoscopeData}
            value={this.props.value}
          />
        ) : (this.props.value === 32 || this.props.value === 33) &&
          this.state.horoTransit !== null ? (
          <NatalChartTrans
            className={`svg-${this.props.section}`}
            id={`container-${this.props.section}`}
            data={this.state.horoTransit}
            t={t}
            Asc={t("Asc")}
          />
        ) : this.props.value === 34 ? (
          <NatalChartsD1
            data={this.props.horoscopeData.swansh_kundali.list_of_houses}
            className={`svg-${this.props.section}`}
            id={`container-${this.props.section}`}
            w="400"
            h="450"
            xview="340"
            yview="215"
            parent="cview"
            t={t}
            Asc={t("Asc")}
          />
        ) : this.props.value === 35 ? (
          <NatalChartsD1
            data={this.props.horoscopeData.karkansh_kundali.list_of_houses}
            className={`svg-${this.props.section}`}
            id={`container-${this.props.section}`}
            w="400"
            h="450"
            xview="340"
            yview="215"
            parent="cview"
            t={t}
            Asc={t("Asc")}
          />
        ) : this.props.value === 36 ? (
          <NatalChartLuminary
            data={this.ModifyToChartDataLuminary(
              this.props.horoscopeData.non_luminaries,
              t
            )}
            className={`svg-${this.props.section}`}
            w="400"
            h="450"
            xview="340"
            yview="215"
            parent="cview"
            t={t}
          />
        ) : this.props.value === 37 ? (
          <NatalChartAsc
            data={this.ModifyToChartDataAsc(
              this.props.horoscopeData.special_ascendants,
              t
            )}
            className={`svg-${this.props.section}`}
            w="400"
            h="450"
            xview="340"
            yview="215"
            parent="cview"
            t={t}
          />
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withTranslation()(SectionComponent);
