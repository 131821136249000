import React, { Component } from "react";
import { Table, Tabs, Tab, InputGroup } from "react-bootstrap";
import { GlobalContext } from "../../../mycontext";
import Sideform from "../../SideComponents/sideform";
import ResetButton from "../ResetButton/ResetButton";
import ImageMapper from "../ImageMapper";
import "./PlanetPositions.css";
import { withTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import Api from "../../../API/Api";
import NatalChartAsc from "./NatalChartAsc";
import NatalChartLuminary from "./NatalChartLuminary";
import Row from "react-bootstrap/Row";
// import NatalChartKundali from "./NatalChartKundali";
import NatalChartsD1 from "../NatalCharts/NatalChartsD1";
import {getQueryParam} from '../../utils/utility';

const calculateSpeed = (num) => {
  num = num * 60;
  let mins = num.toString().split(".")[0];
  let sec = parseFloat("0." + num.toString().split(".")[1]);

  let secs = (sec * 60).toString().split(".")[0];
  if (num > 0) {
    return `${mins}' ${secs}''`;
  } else {
    return `${0 - parseInt(mins)}' ${secs}'' (R)`;
  }
};
const timeFunction = (time) => {
  let splitTimeAMPM = time.split(" ");

  let splitTime = splitTimeAMPM[0].split(":");

  let h = splitTime[0];
  let m = splitTime[1];
  let s = splitTime[2];

  if (splitTimeAMPM[1] === "AM" && parseInt(h) === 12) {
    h = "00";
  } else if (splitTimeAMPM[1] === "PM" && parseInt(h) === 12) {
    h = "12";
  } else if (splitTimeAMPM[1] === "PM" && parseInt(h) !== 12) {
    h = (parseInt(h) + 12).toString();
  }

  return [h, m, s];
};

const Lineify = (obj) => {
  if (typeof obj !== "undefined") {
    if (obj.length > 1) {
      let str = "";
      for (let i of obj) {
        str = str + i + "\n";
      }
      // console.log(str);
      return str;
    } else {
      return obj;
    }
  }
};
var sun, moon, mars, mercury, jupiter, venus, saturn;
const RashiMap = {
  11: "Aquarius",
  1: "Aries",
  4: "Cancer",
  10: "Capricorn",
  3: "Gemini",
  5: "Leo",
  7: "Libra",
  12: "Pisces",
  9: "Sagittarius",
  8: "Scorpio",
  2: "Taurus",
  6: "Virgo",
};

const NatalChartMapper = {
  dhoom_rdms: "Dhoom",
  vyatipaath_rdms: "Vyatipaath",
  parivesh_rdms: "Parivesh",
  indrachaap_rdms: "Indrachaap",
  indrachap: "Indra Chap",
  upketu_rdms: "Upketu",
  bhavalagna: "Bhava Lagna",
  horalagna: "Hora Lagna",
  ghatilagna: "Ghati Lagna",
  varnad: "Varnad",
  pranpada: "Pran Pada",
  kaallagna: "Kaal Lagna",
  yamghant: "Yamghant",
  paridi: "Paridi",
  ardhprahar: "Ardhprahar",
  gulik: "Gulik",
  mrityulagna: "Mrityu Lagna",
  pranpada: "Pran Pada"
};

const KarakMapper = {
  atma_karak: "Atma Karak",
  amatya_karak: "Amatya Karak",
  bhrata_karak: "Bhrata Karak",
  matra_karak: "Matra Karak",
  putra_karak: "Putra Karak",
  jaati_karak: "Jaati Karak",
  stri_karak: "Stri Karak",
};

let modifyLuminaryName = (name) => {
  // if (localStorage.getItem("User Default Language") === "English") {
  if (name === "dhoom_rdms") return "Dhoom";
  else if (name === "vyatipaath_rdms") return "Vyatipaath";
  else if (name === "parivesh_rdms") return "Parivesh";
  else if (name === "indrachaap_rdms") return "Indrachaap";
  else if (name === "upketu_rdms") return "Upketu";
};

let modifySpecialAscendantsName = (name) => {
  if (name === "bhavalagna") return "Bhava Lagna";
  else if (name === "horalagna") return "Hora Lagna";
  else if (name === "ghatilagna") return "Ghati Lagna";
  else if (name === "varnad") return "Varnad";
  else if (name === "pranpada") return "Pran Pada";
  else if (name === "indrachap") return "Indra Chap";
  else if (name === "kaallagna") return "Kaal Lagna";
  else if (name === "paridi") return "Paridi";
  else if (name === "gulik") return "Gulik";
  else if (name === "arudh") return "Arudh";
  else if (name === "mrityulagna") return "Mrityu Lagna";
  else if (name === "yamghant") return "Yamghant";
  else if (name === "ardhprahar") return "Ardhprahar";
};

let calculateHouseNumber = (rashi_num, asc_num) => {
  // console.log(rashi_num, asc_num);
  let house = rashi_num - asc_num + 1;
  if (house <= 0) {
    house = house + 12;
  }
  return house;
};

let LuminaryRashiName = (data, val) => {
  if (typeof data !== "undefined") {
    if (data[val][1] || data[val][2] || data[val][3] > 0) {
      return RashiMap[data[val][0] + 1];
    } else {
      return RashiMap[data[val][0]];
    }
  }
};

let LuminaryRashiNum = (data, val) => {
  if (typeof data !== "undefined") {
    if (data[val][1] || data[val][2] || data[val][3] > 0) {
      return data[val][0] + 1;
    } else {
      return data[val][0];
    }
  }
};

let LuminaryRashiNumAsc = (data, val) => {
  if (typeof data !== "undefined" && typeof data[val] !== "string") {
    if (data[val][1] || data[val][2] || data[val][3] > 0) {
      return data[val][0] + 1;
    } else {
      return data[val][0];
    }
  }
};

function extract_image_link(name) {
  let imglink = "/assets/Planets/";
  return imglink + ImageMapper[name] + ".png";
}
function extract_image_link_nakshatra(name) {
  let imglink = "/assets/Nakshatra/";
  // console.log("iconsssss", name);
  return imglink + ImageMapper[name] + ".svg";
}

function arrayContainsPlus(objArr) {
  var splitArr = objArr.split("+");
  var planetData = splitArr[splitArr.length - 1];
  var newArr,
    specialVal = "";
  if (
    splitArr[splitArr.length - 1].includes("(Special)") ||
    splitArr[splitArr.length - 1].includes("(विशिष्ट)")
  ) {
    specialVal = splitArr[splitArr.length - 1].includes("(Special)")
      ? "(Special)"
      : "(विशिष्ट)";
    newArr = splitArr[splitArr.length - 1].split(" ");
    planetData = newArr[1];
  } else if (
    splitArr[splitArr.length - 1].includes(
      "(Ordinary)" || splitArr[splitArr.length - 1].includes("(साधारण)")
    )
  ) {
    specialVal = splitArr[splitArr.length - 1].includes("(Ordinary)")
      ? "(Ordinary)"
      : "(साधारण)";
    newArr = splitArr[splitArr.length - 1].split(" ");
    planetData = newArr[1];
  } else if (
    !splitArr[splitArr.length - 1].includes("(Special)") ||
    !splitArr[splitArr.length - 1].includes("(Ordinary)") ||
    !splitArr[splitArr.length - 1].includes("(विशिष्ट)") ||
    !splitArr[splitArr.length - 1].includes("(साधारण)")
  ) {
    let sval = splitArr[splitArr.length - 1].replace(/[()]/g, "");
    specialVal = "";
    newArr = sval.split(" ");
    planetData = newArr[1];
  }
  return [splitArr, planetData];
}
class PlanetPositions extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      horoscopeData:
        JSON.parse(window.localStorage.getItem("horoscopeData")) || null,
      activeTab: props.activeTab || 1,
      requestParamsHoroscope:
        JSON.parse(window.localStorage.getItem("requestParamsHoroscope")) ||
        null,
      galacticGraph: "",
      pushkarnavamshaGraph: "",
      renderChoice: "basic",
      karkanshTableData: null,

      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  renderElement = (value) => {
    this.setState({ renderChoice: value });
  };
  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let queryString = getQueryParam('cb');
    if(queryString){
     // alert(queryString);
      this.setState({cb_view:true});
      this.setState({cb_horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
      this.setState({cb_requestParamsHoroscope:JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`))});

      this.setState({horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
      this.setState({requestParamsHoroscope:JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`))});
    }
    else{

    if (
      this.state.requestParamsHoroscope !== null &&
      Object.keys(this.context.requestParamsHoroscope).length === 0
    ) {
      this.context.setRequestParamsHoroscope(this.state.requestParamsHoroscope);
    }
    if (this.state.horoscopeData === null) {
      this.context.handleHoroscopeForm(true, "open");
    } else {
      this.context.setHoroscopeData(this.state.horoscopeData);
      this.createKarkanshData(this.state.horoscopeData.karkansh_kundali);
    }
  }
  }

  componentDidUpdate(prevProps) {
    if (this.props.horoscopeData !== prevProps.horoscopeData) {
      this.setState({ horoscopeData: this.context.horoscopeData }, () =>
        this.createKarkanshData(this.state.horoscopeData.karkansh_kundali)
      );

      if (this.state.activeTab === "7") {
        (this.state.cb_view)? this.fetchGalactic(this.state.cb_requestParamsHoroscope,this.state.cb_horoscopeData) :
        this.fetchGalactic(this.context.requestParamsHoroscope,this.props.horoscopeData);
      }
      if (this.state.activeTab === "8") {
        (this.state.cb_view)? this.fetchPushkarNavamsha( this.state.cb_requestParamsHoroscope,this.state.cb_horoscopeData) : 
        this.fetchPushkarNavamsha( this.context.requestParamsHoroscope,this.props.horoscopeData);
      }
    }
  }

  renderElement = (value) => {
    if (value === "additionalAttributes") {
      this.setState({ renderChoice: "additionalAttributes" });
    } else if (value === "basic") {
      this.setState({ renderChoice: "basic" });
    }
  };

  handleSelect(selectedTab) {
    if (selectedTab === "7") {
      this.setState({ pushkarnavamshaGraph: "" });

      (this.state.cb_view)? this.fetchGalactic(this.state.cb_requestParamsHoroscope,this.state.cb_horoscopeData):
      this.fetchGalactic(this.context.requestParamsHoroscope,this.state.horoscopeData);

    } else if (selectedTab === "8") {
      this.setState({ galacticGraph: "" });

      (this.state.cb_view)? this.fetchPushkarNavamsha(
        this.state.cb_requestParamsHoroscope,
        this.state.cb_horoscopeData
      ) :
      this.fetchPushkarNavamsha(
        this.context.requestParamsHoroscope,
        this.state.horoscopeData
      );
    }

    this.setState({
      activeTab: selectedTab,
    });
  }

  fetchGalactic(params, data) {
    let API = new Api();
    let splitDate = data.date_of_birth.split("-");
    let splitTime = timeFunction(data.time_of_birth);
    API.fetchGalactic(
      data.native_name,
      data.basic_details.gender,
      splitDate[0],
      splitDate[1],
      splitDate[2],
      splitTime[0],
      splitTime[1],
      splitTime[2].slice(0, 2),
      params.latitude,
      params.longitude,
      params.offset,
      params.name,
      params.state,
      params.country,
      params.reference_longitude_for_timezone,
      params.timezone,
      localStorage.getItem("omp_token")
    )
      .then((result) => {
        // console.log(result.data);
        // console.log(JSON.stringify(result.data.data));
        var img = result.data.data;
        img.replace(/[\r\n]+/gm, "");
        //console.log(img);
        this.setState({ galacticGraph: img });
      })
      .catch((e) => {
        // console.log(e);
      });
  }
  getsvg = () => {
    return (
      <span dangerouslySetInnerHTML={{ __html: this.state.galacticGraph }} />
    );
  };
  fetchPushkarNavamsha(params, data) {
    let API = new Api();
    let splitDate = data.date_of_birth.split("-");
    let splitTime = timeFunction(data.time_of_birth);
    API.fetchPushkarNavamsha(
      data.native_name,
      data.basic_details.gender,
      splitDate[0],
      splitDate[1],
      splitDate[2],
      splitTime[0],
      splitTime[1],
      splitTime[2].slice(0, 2),
      params.latitude,
      params.longitude,
      params.offset,
      params.name,
      params.state,
      params.country,
      params.reference_longitude_for_timezone,
      params.timezone,
      localStorage.getItem("omp_token")
    )
      .then((result) => {
        // console.log(result.data);
        // console.log(JSON.stringify(result.data.data));
        var img = result.data.data;
        img.replace(/[\r\n]+/gm, "");
        // console.log(img);
        this.setState({ pushkarnavamshaGraph: img });
      })
      .catch((e) => {
        // console.log(e);
      });
  }
  getPushkarNavamshaSvg = () => {
    return (
      <span
        dangerouslySetInnerHTML={{ __html: this.state.pushkarnavamshaGraph }}
      />
    );
  };

  LuminaryRashiName = (data, val) => {
    if (typeof data !== "undefined") {
      if (typeof data[val] === "object") {
        if (data[val][1] || data[val][2] || data[val][3] > 0) {
          return this.props.t(RashiMap[data[val][0] + 1]);
        } else {
          return this.props.t(RashiMap[data[val][0]]);
        }
      }
    }
  };

  LuminaryRashiNameImage = (data, val) => {
    if (typeof data !== "undefined") {
      if (typeof data[val] === "object") {
        if (data[val][1] || data[val][2] || data[val][3] > 0) {
          return RashiMap[data[val][0] + 1];
        } else {
          return RashiMap[data[val][0]];
        }
      }
    }
  };

  ModifyToChartDataLuminary = (data, t) => {
    if (data !== "undefined") {
      let json = [];
      let RashiNumberMapper = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      };

      for (let val of Object.keys(data)) {
        let rashiNum = LuminaryRashiNum(data, val);
        RashiNumberMapper[rashiNum] = [
          ...RashiNumberMapper[rashiNum],
          t(NatalChartMapper[val]),
        ];
      }
      let ascNum =
        this.state.horoscopeData.natal_chart.list_of_houses[0].rashi_num;
      for (let i = ascNum; i <= 12; i++) {
        let innerjson = {};
        innerjson.rashi = i;
        innerjson.planets = RashiNumberMapper[i];
        innerjson.house = i;
        json.push(innerjson);
      }
      for (let i = 1; i < ascNum; i++) {
        let innerjson = {};
        innerjson.rashi = i;
        innerjson.planets = RashiNumberMapper[i];
        innerjson.house = i;
        json.push(innerjson);
      }

      return json;
    }
  };

  ModifyToChartDataAsc = (data, t) => {
    if (data !== "undefined") {
      let json = [];
      let RashiNumberMapper = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
      };

      for (let val of Object.keys(data)) {
        console.log(data);
        let rashiNum = LuminaryRashiNumAsc(data, val);
        if (!isNaN(rashiNum)) {
         
          if (Array.isArray(RashiNumberMapper[rashiNum])) {
            const newValue = t(NatalChartMapper[val]);
            if (newValue !== undefined && newValue !== null) {
                RashiNumberMapper[rashiNum] = [
                    ...RashiNumberMapper[rashiNum],
                    newValue
                ];
            }
        } else {
            // console.log("inside Elese");
            // console.log(RashiNumberMapper[rashiNum]);
            RashiNumberMapper[rashiNum] = [];
        }
        }
      }
      let ascNum =
        this.state.horoscopeData.natal_chart.list_of_houses[0].rashi_num;
      for (let i = ascNum; i <= 12; i++) {
        let innerjson = {};
        innerjson.rashi = i;
        innerjson.planets = RashiNumberMapper[i];
        innerjson.house = i;
        json.push(innerjson);
      }
      for (let i = 1; i < ascNum; i++) {
        let innerjson = {};
        innerjson.rashi = i;
        innerjson.planets = RashiNumberMapper[i];
        innerjson.house = i;
        json.push(innerjson);
      }

      return json;
    }
  };

  createKarkanshData = (data) => {
    let mainjson = [];
    for (let i of Object.keys(data.nature_to_planet_map)) {
      let json = {};
      json["Karak"] = i;
      mainjson.push(json);
    }
    let count = 0;
    for (let i of Object.keys(data.nature_to_planet_map)) {
      mainjson[count]["Planet"] = data.nature_to_planet_map[i];
      mainjson[count]["Position"] =
        data.planet_to_rdms_map[data.nature_to_planet_map[i]];
      count = count + 1;
    }
    // console.log(mainjson);
    this.setState({ karkanshTableData: mainjson });
  };
  render() {
    const { t, i18n } = this.props;
    if (this.state.horoscopeData !== null || this.state.cb_horoscopeData !== null) {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"planetPositions"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />

          <div className="Planet-positions px-1">
            <ResetButton />
            <div className="panel with-nav-tabs panel-warning">
              <div className="panel-heading">
                <Tabs
                  className="myClass col-md-9"
                  activeKey={this.state.activeTab}
                  onSelect={this.handleSelect}
                >
                  <Tab eventKey={1} title={t("All")}>
                    <center className="row">
                      <h2 className="title text-right col-md-7 col-12 pt-2 pl-5">
                        {t("Planet Positions")}
                      </h2>

                      <div className="col-md-3 col-12 pt-4">
                        <label
                          style={{
                            fontSize: "16px",
                            fontWeight: "normal",
                            fontFamily: "Lucida Bright",
                            color: "#666",
                          }}
                        >
                          <input
                            type="radio"
                            name="optBasic"
                            value="Basic"
                            checked={this.state.renderChoice === "basic"}
                            onClick={() => this.renderElement("basic")}
                          />{" "}
                          {t("Basic")}
                        </label>
                        <label
                          style={{
                            fontSize: "16px",
                            fontWeight: "normal",
                            fontFamily: "Lucida Bright",
                            color: "#666",
                          }}
                        >
                          <input
                            type="radio"
                            name="optBasic"
                            value="Additional Attributes"
                            style={{ marginLeft: "20px" }}
                            onClick={() =>
                              this.renderElement("additionalAttributes")
                            }
                            readOnly
                          />{" "}
                          {t("Additional Attributes")}
                        </label>
                      </div>
                    </center>

                    {this.state.renderChoice === "basic" ? (
                      <Table responsive="xs" className="content-table-pp">
                        <thead>
                          <tr>
                            <th>{t("Planet")}</th>
                            <th>{t("Absolute Degree")}</th>
                            <th>{t("Position")}</th>
                            <th>{t("Motion")}</th>
                            <th>{t("Nakshtra")}</th>
                            <th>{t("Pada")}</th>
                            <th>{t("Nakshtra Lord")}</th>
                            <th>{t("Combust")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                          this.state.horoscopeData.planetary_positions.detailedPlanetPositions.map(
                            function (val, i) {
                              return (
                                <tr key={val + i}>
                                  <td>
                                    {val["planet_name"] !== "Ascendant" &&
                                    val["planet_name"] !== "लग्न" ? (
                                      <img
                                        className="PpImages"
                                        alt={val["planet_name"].slice(0, 3)}
                                        src={extract_image_link(
                                          val["planet_name"]
                                        )}
                                      ></img>
                                    ) : (
                                      <></>
                                    )}
                                    {val["planet_name"]}
                                  </td>
                                  <td>{val["abs_degree"]}</td>
                                  <td>
                                    {" "}
                                    {val["r_d_m_s"] ? (
                                      <img
                                        className="nakshatra-images"
                                        alt={val["nakshtra"]}
                                        src={`/assets/Zodiac/${
                                          ImageMapper[
                                            val["r_d_m_s"].split(" ")[1]
                                          ]
                                        }.svg`}
                                      ></img>
                                    ) : (
                                      <></>
                                    )}
                                    {val["r_d_m_s"]}
                                  </td>
                                  <td>{val["motion"]}</td>
                                  <td>
                                    {" "}
                                    {val["nakshtra"] ? (
                                      <img
                                        className="nakshatra-images"
                                        alt={val["nakshtra"]}
                                        src={extract_image_link_nakshatra(
                                          val["nakshtra"]
                                        )}
                                      ></img>
                                    ) : (
                                      <></>
                                    )}
                                    {`${val["nakshtra"]}`}
                                  </td>
                                  <td>{val["Nakshtra pada"]}</td>
                                  <td>
                                    {val["Nakshatra lord"] !== "Ascendant" ? (
                                      <img
                                        className="PpImages"
                                        alt={val["Nakshtra lord"].slice(0, 3)}
                                        src={extract_image_link(
                                          val["Nakshtra lord"]
                                        )}
                                      ></img>
                                    ) : (
                                      <></>
                                    )}
                                    {val["Nakshtra lord"]}
                                  </td>
                                  <td>
                                    {val["combust"] === true
                                      ? t("Yes")
                                      : val["combust"] === false
                                      ? t("No")
                                      : "-"}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    ) : (
                      <Table responsive="xs" className="content-table-pp">
                        <thead>
                          <tr>
                            <th>{t("Planet")}</th>
                            <th>{t("Speed")}</th>
                            <th>{t("Dignity")}</th>
                            <th>{t("Shad Bala in Rupas")}</th>
                            <th>{t("Minimum Requirement in Rupas")}</th>
                            <th>{t("Strength")}</th>
                            <th>{t("Pushkar Navamsha")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.horoscopeData.additional_attributes.map(
                            function (val, i) {
                              return (
                                <tr key={val + i}>
                                  <td>
                                    {val["planet_name"] !== "Ascendant" ? (
                                      <img
                                        className="PpImages"
                                        alt={val["planet_name"].slice(0, 3)}
                                        src={extract_image_link(
                                          val["planet_name"]
                                        )}
                                      ></img>
                                    ) : (
                                      <></>
                                    )}
                                    {val["planet_name"]}
                                  </td>
                                  <td className="text-center">
                                    {calculateSpeed(
                                      Number(val["speed"]).toFixed(4)
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {val["dignity"] === ""
                                      ? "-"
                                      : val["dignity"]}
                                  </td>
                                  <td className="text-center">
                                    {val["shadbala_in_rupas"]}
                                  </td>
                                  <td className="text-center">
                                    {val["minimum_requirements_in_rupas"]}
                                  </td>
                                  <td className="text-center">
                                    {Number(val["strength"]).toFixed(2) + "%"}
                                  </td>
                                  <td className="text-center">
                                    {" "}
                                    {val["pushkar_navamsha"] === true
                                      ? t("Yes")
                                      : val["pushkar_navamsha"] === false
                                      ? t("No")
                                      : "-"}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    )}
                  </Tab>
                  <Tab eventKey={2} title={t("Functional Malefic Benefic")}>
                    <center className="row">
                      <h2 className="title text-right col-md-8 col-12 pt-2 pl-5">
                        {t("Functional Malefic Benefic")}{" "}
                      </h2>
                    </center>
                    <Table
                      responsive="xs"
                      className="content-table-pp"
                      id="malefic_benefic"
                    >
                      <thead>
                        <tr>
                          <th>{t("Planets")}</th>
                          <th>{t("Benefic Planets")}</th>
                          <th>{t("Malefic Planets")}</th>
                          <th>{t("Markesh")}</th>
                          <th>{t("Rajyoga Planets")}</th>
                          <th>{t("Rajyoga Antidote")}</th>
                          <th>{t("Ordinary")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {typeof this.state.horoscopeData
                          .functional_malefic_benefic !== "undefined" ? (
                          Object.keys(
                            this.state.horoscopeData.functional_malefic_benefic
                          ).map((val, i) => {
                            let obj =
                              this.state.horoscopeData
                                .functional_malefic_benefic;

                            return (
                              <tr key={val + i}>
                                <td className="text-center">
                                  {
                                    <img
                                      className="PpImages"
                                      alt={t(
                                        this.state.horoscopeData.planets[i]
                                      )}
                                      src={extract_image_link(
                                        t(this.state.horoscopeData.planets[i])
                                      )}
                                    ></img>
                                  }
                                  {t(this.state.horoscopeData.planets[i])}
                                </td>
                                {/* benefic_planets */}
                                <td className="text-center">
                                  {Object.keys(obj["benefic_planets"]).map(
                                    (j) => {
                                      if (
                                        obj["benefic_planets"][j] ===
                                        t(this.state.horoscopeData.planets[i])
                                      ) {
                                        return (
                                          <span>
                                            <FaCheck
                                              style={{ color: "green" }}
                                            />{" "}
                                          </span>
                                        );
                                      } else if (
                                        obj["benefic_planets"][j].includes("+")
                                      ) {
                                        var splitArr = arrayContainsPlus(
                                          obj["benefic_planets"][j]
                                        )[0];
                                        var planetData = arrayContainsPlus(
                                          obj["benefic_planets"][j]
                                        )[1];

                                        if (
                                          planetData ===
                                          t(this.state.horoscopeData.planets[i])
                                        ) {
                                          for (
                                            let x = 0;
                                            x < splitArr.length - 1;
                                            x++
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {" "}
                                                <FaCheck
                                                  style={{ color: "green" }}
                                                />
                                                {" + "} {splitArr[x]} {""}
                                                {specialVal}
                                              </span>
                                            );
                                          }
                                        }
                                      } else if (
                                        obj["benefic_planets"][j].includes(
                                          "Special"
                                        ) ||
                                        obj["benefic_planets"][j].includes(
                                          "Ordinary"
                                        ) ||
                                        obj["benefic_planets"][j].includes(
                                          "विशिष्ट"
                                        ) ||
                                        (obj["benefic_planets"][j].includes(
                                          "साधारण"
                                        ) &&
                                          !obj["benefic_planets"][j].includes(
                                            "+"
                                          ))
                                      ) {
                                        var newArr, planetData, specialVal;
                                        if (
                                          obj["benefic_planets"][j].includes(
                                            "(Special)"
                                          ) ||
                                          obj["benefic_planets"][j].includes(
                                            "विशिष्ट"
                                          )
                                        ) {
                                          specialVal = obj["benefic_planets"][
                                            j
                                          ].includes("(Special)")
                                            ? "(Special)"
                                            : "(विशिष्ट)";
                                          newArr =
                                            obj["benefic_planets"][j].split(
                                              " "
                                            );
                                          planetData = newArr[0];
                                          if (
                                            planetData ===
                                            t(
                                              this.state.horoscopeData.planets[
                                                i
                                              ]
                                            )
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {" "}
                                                {
                                                  <FaCheck
                                                    style={{ color: "green" }}
                                                  />
                                                }
                                                {planetData}
                                                {"+"}
                                                {specialVal}{" "}
                                              </span>
                                            );
                                          }
                                        } else if (
                                          obj["benefic_planets"][j].includes(
                                            "(Ordinary)"
                                          ) ||
                                          obj["benefic_planets"][j].includes(
                                            "साधारण"
                                          )
                                        ) {
                                          specialVal = obj["benefic_planets"][
                                            j
                                          ].includes("(Ordinary)")
                                            ? "(Ordinary)"
                                            : "(साधारण)";
                                          newArr =
                                            obj["benefic_planets"][j].split(
                                              " "
                                            );
                                          planetData = newArr[0];
                                          if (
                                            planetData ===
                                            t(
                                              this.state.horoscopeData.planets[
                                                i
                                              ]
                                            )
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {
                                                  <FaCheck
                                                    style={{ color: "green" }}
                                                  />
                                                }
                                                {specialVal}{" "}
                                              </span>
                                            );
                                          }
                                        }
                                      }
                                    }
                                  )}
                                </td>
                                {/* malefic_planets */}
                                <td className="text-center">
                                  {Object.keys(obj["malefic_planets"]).map(
                                    (j) => {
                                      if (
                                        obj["malefic_planets"][j] ===
                                        t(this.state.horoscopeData.planets[i])
                                      ) {
                                        return (
                                          <span>
                                            <FaCheck
                                              style={{ color: "green" }}
                                            />{" "}
                                          </span>
                                        );
                                      } else if (
                                        obj["malefic_planets"][j].includes("+")
                                      ) {
                                        var splitArr = arrayContainsPlus(
                                          obj["malefic_planets"][j]
                                        )[0];
                                        var planetData = arrayContainsPlus(
                                          obj["malefic_planets"][j]
                                        )[1];

                                        if (
                                          planetData ===
                                          t(this.state.horoscopeData.planets[i])
                                        ) {
                                          for (
                                            let x = 0;
                                            x < splitArr.length - 1;
                                            x++
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {" "}
                                                <FaCheck
                                                  style={{ color: "green" }}
                                                />
                                                {" + "} {splitArr[x]} {""}
                                                {specialVal}
                                              </span>
                                            );
                                          }
                                        }
                                      } else if (
                                        obj["malefic_planets"][j].includes(
                                          "Special"
                                        ) ||
                                        obj["malefic_planets"][j].includes(
                                          "Ordinary"
                                        ) ||
                                        obj["malefic_planets"][j].includes(
                                          "विशिष्ट"
                                        ) ||
                                        (obj["malefic_planets"][j].includes(
                                          "साधारण"
                                        ) &&
                                          !obj["malefic_planets"][j].includes(
                                            "+"
                                          ))
                                      ) {
                                        var newArr, planetData, specialVal;
                                        if (
                                          obj["malefic_planets"][j].includes(
                                            "(Special)"
                                          ) ||
                                          obj["malefic_planets"][j].includes(
                                            "विशिष्ट"
                                          )
                                        ) {
                                          specialVal = obj["malefic_planets"][
                                            j
                                          ].includes("(Special)")
                                            ? "(Special)"
                                            : "(विशिष्ट)";
                                          newArr =
                                            obj["malefic_planets"][j].split(
                                              " "
                                            );
                                          planetData = newArr[0];
                                          if (
                                            planetData ===
                                            t(
                                              this.state.horoscopeData.planets[
                                                i
                                              ]
                                            )
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {" "}
                                                {
                                                  <FaCheck
                                                    style={{ color: "green" }}
                                                  />
                                                }
                                                {planetData}
                                                {"+"}
                                                {specialVal}{" "}
                                              </span>
                                            );
                                          }
                                        } else if (
                                          obj["malefic_planets"][j].includes(
                                            "(Ordinary)"
                                          ) ||
                                          obj["malefic_planets"][j].includes(
                                            "साधारण"
                                          )
                                        ) {
                                          specialVal = obj["malefic_planets"][
                                            j
                                          ].includes("(Ordinary)")
                                            ? "(Ordinary)"
                                            : "(साधारण)";
                                          newArr =
                                            obj["malefic_planets"][j].split(
                                              " "
                                            );
                                          planetData = newArr[0];
                                          if (
                                            planetData ===
                                            t(
                                              this.state.horoscopeData.planets[
                                                i
                                              ]
                                            )
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {
                                                  <FaCheck
                                                    style={{ color: "green" }}
                                                  />
                                                }
                                                {specialVal}{" "}
                                              </span>
                                            );
                                          }
                                        }
                                      }
                                    }
                                  )}
                                </td>
                                {/* markesh */}
                                <td className="text-center">
                                  {Object.keys(obj["markesh"]).map((j) => {
                                    if (
                                      obj["markesh"][j] ===
                                      t(this.state.horoscopeData.planets[i])
                                    ) {
                                      return (
                                        <span>
                                          <FaCheck style={{ color: "green" }} />{" "}
                                        </span>
                                      );
                                    } else if (
                                      obj["markesh"][j].includes("+")
                                    ) {
                                      var splitArr = arrayContainsPlus(
                                        obj["markesh"][j]
                                      )[0];
                                      var planetData = arrayContainsPlus(
                                        obj["markesh"][j]
                                      )[1];

                                      if (
                                        planetData ===
                                        t(this.state.horoscopeData.planets[i])
                                      ) {
                                        for (
                                          let x = 0;
                                          x < splitArr.length - 1;
                                          x++
                                        ) {
                                          return (
                                            <span style={{ color: "green" }}>
                                              {" "}
                                              <FaCheck
                                                style={{ color: "green" }}
                                              />
                                              {" + "} {splitArr[x]} {""}
                                              {specialVal}
                                            </span>
                                          );
                                        }
                                      }
                                    } else if (
                                      obj["markesh"][j].includes("Special") ||
                                      obj["markesh"][j].includes("Ordinary") ||
                                      obj["markesh"][j].includes("विशिष्ट") ||
                                      (obj["markesh"][j].includes("साधारण") &&
                                        !obj["markesh"][j].includes("+"))
                                    ) {
                                      var newArr, planetData, specialVal;
                                      if (
                                        obj["markesh"][j].includes(
                                          "(Special)"
                                        ) ||
                                        obj["markesh"][j].includes("विशिष्ट")
                                      ) {
                                        specialVal = obj["markesh"][j].includes(
                                          "(Special)"
                                        )
                                          ? "(Special)"
                                          : "(विशिष्ट)";
                                        newArr = obj["markesh"][j].split(" ");
                                        planetData = newArr[0];
                                        if (
                                          planetData ===
                                          t(this.state.horoscopeData.planets[i])
                                        ) {
                                          return (
                                            <span style={{ color: "green" }}>
                                              {" "}
                                              {
                                                <FaCheck
                                                  style={{ color: "green" }}
                                                />
                                              }
                                              {/* {planetData}
                                                {"+"} */}
                                              {specialVal}{" "}
                                            </span>
                                          );
                                        }
                                      } else if (
                                        obj["markesh"][j].includes(
                                          "(Ordinary)"
                                        ) ||
                                        obj["markesh"][j].includes("साधारण")
                                      ) {
                                        specialVal = obj["markesh"][j].includes(
                                          "(Ordinary)"
                                        )
                                          ? "(Ordinary)"
                                          : "(साधारण)";
                                        newArr = obj["markesh"][j].split(" ");
                                        planetData = newArr[0];
                                        if (
                                          planetData ===
                                          t(this.state.horoscopeData.planets[i])
                                        ) {
                                          return (
                                            <span style={{ color: "green" }}>
                                              {
                                                <FaCheck
                                                  style={{ color: "green" }}
                                                />
                                              }
                                              {specialVal}{" "}
                                            </span>
                                          );
                                        }
                                      }
                                    }
                                  })}
                                </td>
                                {/* rajyoga_planets */}
                                <td className="text-center">
                                  {Object.keys(obj["rajyoga_planets"]).map(
                                    (j) => {
                                      if (
                                        obj["rajyoga_planets"][j] ===
                                        t(this.state.horoscopeData.planets[i])
                                      ) {
                                        return (
                                          <span>
                                            <FaCheck
                                              style={{ color: "green" }}
                                            />{" "}
                                          </span>
                                        );
                                      } else if (
                                        obj["rajyoga_planets"][j].includes("+")
                                      ) {
                                        var splitArr = arrayContainsPlus(
                                          obj["rajyoga_planets"][j]
                                        )[0];
                                        var planetData = arrayContainsPlus(
                                          obj["rajyoga_planets"][j]
                                        )[1];

                                        if (
                                          planetData ===
                                          t(this.state.horoscopeData.planets[i])
                                        ) {
                                          for (
                                            let x = 0;
                                            x < splitArr.length - 1;
                                            x++
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {" "}
                                                <FaCheck
                                                  style={{ color: "green" }}
                                                />
                                                {" + "} {splitArr[x]} {""}
                                                {specialVal}
                                              </span>
                                            );
                                          }
                                        }
                                      } else if (
                                        obj["rajyoga_planets"][j].includes(
                                          "Special"
                                        ) ||
                                        obj["rajyoga_planets"][j].includes(
                                          "Ordinary"
                                        ) ||
                                        obj["rajyoga_planets"][j].includes(
                                          "विशिष्ट"
                                        ) ||
                                        (obj["rajyoga_planets"][j].includes(
                                          "साधारण"
                                        ) &&
                                          !obj["rajyoga_planets"][j].includes(
                                            "+"
                                          ))
                                      ) {
                                        var newArr, planetData, specialVal;
                                        if (
                                          obj["rajyoga_planets"][j].includes(
                                            "(Special)"
                                          ) ||
                                          obj["rajyoga_planets"][j].includes(
                                            "विशिष्ट"
                                          )
                                        ) {
                                          specialVal = obj["rajyoga_planets"][
                                            j
                                          ].includes("(Special)")
                                            ? "(Special)"
                                            : "(विशिष्ट)";
                                          newArr =
                                            obj["rajyoga_planets"][j].split(
                                              " "
                                            );
                                          planetData = newArr[0];
                                          if (
                                            planetData ===
                                            t(
                                              this.state.horoscopeData.planets[
                                                i
                                              ]
                                            )
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {" "}
                                                {
                                                  <FaCheck
                                                    style={{ color: "green" }}
                                                  />
                                                }
                                                {planetData}
                                                {"+"}
                                                {specialVal}{" "}
                                              </span>
                                            );
                                          }
                                        } else if (
                                          obj["rajyoga_planets"][j].includes(
                                            "(Ordinary)"
                                          ) ||
                                          obj["rajyoga_planets"][j].includes(
                                            "साधारण"
                                          )
                                        ) {
                                          specialVal = obj["rajyoga_planets"][
                                            j
                                          ].includes("(Ordinary)")
                                            ? "(Ordinary)"
                                            : "(साधारण)";
                                          newArr =
                                            obj["rajyoga_planets"][j].split(
                                              " "
                                            );
                                          planetData = newArr[0];
                                          if (
                                            planetData ===
                                            t(
                                              this.state.horoscopeData.planets[
                                                i
                                              ]
                                            )
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {
                                                  <FaCheck
                                                    style={{ color: "green" }}
                                                  />
                                                }
                                                {specialVal}{" "}
                                              </span>
                                            );
                                          }
                                        }
                                      }
                                    }
                                  )}
                                </td>
                                {/* rajyoga_antidote */}
                                <td className="text-center">
                                  {Object.keys(obj["rajyoga_antidote"]).map(
                                    (j) => {
                                      if (
                                        obj["rajyoga_antidote"][j] ===
                                        t(this.state.horoscopeData.planets[i])
                                      ) {
                                        return (
                                          <span>
                                            <FaCheck
                                              style={{ color: "green" }}
                                            />{" "}
                                          </span>
                                        );
                                      } else if (
                                        obj["rajyoga_antidote"][j].includes("+")
                                      ) {
                                        var splitArr = arrayContainsPlus(
                                          obj["rajyoga_antidote"][j]
                                        )[0];
                                        var planetData = arrayContainsPlus(
                                          obj["rajyoga_antidote"][j]
                                        )[1];

                                        if (
                                          planetData ===
                                          t(this.state.horoscopeData.planets[i])
                                        ) {
                                          for (
                                            let x = 0;
                                            x < splitArr.length - 1;
                                            x++
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {" "}
                                                <FaCheck
                                                  style={{ color: "green" }}
                                                />
                                                {" + "} {splitArr[x]} {""}
                                                {specialVal}
                                              </span>
                                            );
                                          }
                                        }
                                      } else if (
                                        obj["rajyoga_antidote"][j].includes(
                                          "Special"
                                        ) ||
                                        obj["rajyoga_antidote"][j].includes(
                                          "Ordinary"
                                        ) ||
                                        obj["rajyoga_antidote"][j].includes(
                                          "विशिष्ट"
                                        ) ||
                                        (obj["rajyoga_antidote"][j].includes(
                                          "साधारण"
                                        ) &&
                                          !obj["rajyoga_antidote"][j].includes(
                                            "+"
                                          ))
                                      ) {
                                        var newArr, planetData, specialVal;
                                        if (
                                          obj["rajyoga_antidote"][j].includes(
                                            "(Special)"
                                          ) ||
                                          obj["rajyoga_antidote"][j].includes(
                                            "विशिष्ट"
                                          )
                                        ) {
                                          specialVal = obj["rajyoga_antidote"][
                                            j
                                          ].includes("(Special)")
                                            ? "(Special)"
                                            : "(विशिष्ट)";
                                          newArr =
                                            obj["rajyoga_antidote"][j].split(
                                              " "
                                            );
                                          planetData = newArr[0];
                                          if (
                                            planetData ===
                                            t(
                                              this.state.horoscopeData.planets[
                                                i
                                              ]
                                            )
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {" "}
                                                {
                                                  <FaCheck
                                                    style={{ color: "green" }}
                                                  />
                                                }
                                                {planetData}
                                                {"+"}
                                                {specialVal}{" "}
                                              </span>
                                            );
                                          }
                                        } else if (
                                          obj["rajyoga_antidote"][j].includes(
                                            "(Ordinary)"
                                          ) ||
                                          obj["rajyoga_antidote"][j].includes(
                                            "साधारण"
                                          )
                                        ) {
                                          specialVal = obj["rajyoga_antidote"][
                                            j
                                          ].includes("(Ordinary)")
                                            ? "(Ordinary)"
                                            : "(साधारण)";
                                          newArr =
                                            obj["rajyoga_antidote"][j].split(
                                              " "
                                            );
                                          planetData = newArr[0];
                                          if (
                                            planetData ===
                                            t(
                                              this.state.horoscopeData.planets[
                                                i
                                              ]
                                            )
                                          ) {
                                            return (
                                              <span style={{ color: "green" }}>
                                                {
                                                  <FaCheck
                                                    style={{ color: "green" }}
                                                  />
                                                }
                                                {specialVal}{" "}
                                              </span>
                                            );
                                          }
                                        }
                                      }
                                    }
                                  )}
                                </td>
                                {/* ordinary */}
                                <td className="text-center">
                                  {Object.keys(obj["ordinary"]).map((j) => {
                                    if (
                                      obj["ordinary"][j] ===
                                      t(this.state.horoscopeData.planets[i])
                                    ) {
                                      return (
                                        <span>
                                          <FaCheck style={{ color: "green" }} />{" "}
                                        </span>
                                      );
                                    } else if (
                                      obj["ordinary"][j].includes("+")
                                    ) {
                                      var splitArr = arrayContainsPlus(
                                        obj["ordinary"][j]
                                      )[0];
                                      var planetData = arrayContainsPlus(
                                        obj["ordinary"][j]
                                      )[1];

                                      if (
                                        planetData ===
                                        t(this.state.horoscopeData.planets[i])
                                      ) {
                                        for (
                                          let x = 0;
                                          x < splitArr.length - 1;
                                          x++
                                        ) {
                                          return (
                                            <span style={{ color: "green" }}>
                                              {" "}
                                              <FaCheck
                                                style={{ color: "green" }}
                                              />
                                              {" + "} {splitArr[x]} {""}
                                              {specialVal}
                                            </span>
                                          );
                                        }
                                      }
                                    } else if (
                                      obj["ordinary"][j].includes("Special") ||
                                      obj["ordinary"][j].includes("Ordinary") ||
                                      obj["ordinary"][j].includes("विशिष्ट") ||
                                      (obj["ordinary"][j].includes("साधारण") &&
                                        !obj["ordinary"][j].includes("+"))
                                    ) {
                                      var newArr, planetData, specialVal;
                                      if (
                                        obj["ordinary"][j].includes(
                                          "(Special)"
                                        ) ||
                                        obj["ordinary"][j].includes("विशिष्ट")
                                      ) {
                                        specialVal = obj["ordinary"][
                                          j
                                        ].includes("(Special)")
                                          ? "(Special)"
                                          : "(विशिष्ट)";
                                        newArr = obj["ordinary"][j].split(" ");
                                        planetData = newArr[0];
                                        if (
                                          planetData ===
                                          t(this.state.horoscopeData.planets[i])
                                        ) {
                                          return (
                                            <span style={{ color: "green" }}>
                                              {" "}
                                              {
                                                <FaCheck
                                                  style={{ color: "green" }}
                                                />
                                              }
                                              {planetData}
                                              {"+"}
                                              {specialVal}{" "}
                                            </span>
                                          );
                                        }
                                      } else if (
                                        obj["ordinary"][j].includes(
                                          "(Ordinary)"
                                        ) ||
                                        obj["ordinary"][j].includes("साधारण")
                                      ) {
                                        specialVal = obj["ordinary"][
                                          j
                                        ].includes("(Ordinary)")
                                          ? "(Ordinary)"
                                          : "(साधारण)";
                                        newArr = obj["ordinary"][j].split(" ");
                                        planetData = newArr[0];
                                        if (
                                          planetData ===
                                          t(this.state.horoscopeData.planets[i])
                                        ) {
                                          return (
                                            <span style={{ color: "green" }}>
                                              {
                                                <FaCheck
                                                  style={{ color: "green" }}
                                                />
                                              }
                                              {specialVal}{" "}
                                            </span>
                                          );
                                        }
                                      }
                                    }
                                  })}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab eventKey={3} title={t("Planet's States")}>
                    <center className="row">
                      <h2 className="title title text-right col-md-7 col-12 pt-2 pl-5">
                        {t("Planet's States")}
                      </h2>
                    </center>
                    <Table responsive="xs" className="content-table-pp">
                      <thead>
                        <tr>
                          <th>{t("Planet")}</th>
                          <th>{t("Deeptadi")}</th>
                          <th>{t("Baladi")}</th>
                          <th>{t("Jagratadi")}</th>
                          <th>{t("Lajjitadi")}</th>
                          <th>{t("Shayanadi")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.horoscopeData.planets_states.map(function (
                          val,
                          i
                        ) {
                          return (
                            <tr
                              key={val + i}
                              style={{ whiteSpace: "pre-wrap" }}
                            >
                              <td>
                                {" "}
                                <img
                                  className="PpImages"
                                  alt={t(val["planet_name"])}
                                  src={extract_image_link(
                                    t(val["planet_name"])
                                  )}
                                ></img>
                                {t(val["planet_name"])}
                              </td>
                              <td>
                                {Lineify(val["planet_state"]["Deeptadi"])}
                              </td>
                              <td>{Lineify(val["planet_state"]["Baladi"])}</td>
                              <td>
                                {Lineify(val["planet_state"]["Jagratadi"])}
                              </td>
                              <td>
                                {Lineify(val["planet_state"]["Lajjitadi"])}
                              </td>
                              <td>
                                {Lineify(val["planet_state"]["Shayanadi"])}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Tab>
                  <Tab eventKey={4} title={t("Non Luminaries")}>
                    <center className="row">
                      <h2 className="title text-right col-md-7 col-12 pt-2 pl-5">
                        {t("Non Luminaries")}
                      </h2>
                    </center>
                    {typeof this.state.horoscopeData.special_ascendants !==
                    "undefined" ? (
                      <div className="row">
                        <div className="col-md-7">
                          <Table responsive="xs" className="content-table-pp">
                            <thead>
                              <tr>
                                <th style={{ width: "250px" }}>
                                  {t("Non Luminary")}
                                </th>
                                <th style={{ width: "250px" }}>
                                  {t("Position")}
                                </th>
                                <th style={{ width: "250px" }}>{t("House")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {typeof this.state.horoscopeData
                                .non_luminaries !== "undefined" ? (
                                Object.keys(
                                  this.state.horoscopeData.non_luminaries
                                ).map((val, i) => {
                                  return typeof this.state.horoscopeData
                                    .non_luminaries[val] === "object" ? (
                                    <tr key={val + i} style={{}}>
                                      <td>{t(modifyLuminaryName(val))}</td>
                                      <td>
                                        <img
                                          className="nakshatra-images"
                                          alt={this.LuminaryRashiNameImage(
                                            this.state.horoscopeData
                                              .non_luminaries,
                                            val
                                          )}
                                          src={`/assets/Zodiac/${this.LuminaryRashiNameImage(
                                            this.state.horoscopeData
                                              .non_luminaries,
                                            val
                                          )}.svg`}
                                        ></img>{" "}
                                        {`${
                                          this.state.horoscopeData
                                            .non_luminaries[val][1]
                                        }° ${
                                          localStorage.getItem(
                                            "User Default Language"
                                          ) === "English"
                                            ? this.LuminaryRashiName(
                                                this.state.horoscopeData
                                                  .non_luminaries,
                                                val
                                              ).substring(0, 3)
                                            : this.LuminaryRashiName(
                                                this.state.horoscopeData
                                                  .non_luminaries,
                                                val
                                              )
                                        }   ${
                                          this.state.horoscopeData
                                            .non_luminaries[val][2]
                                        }'
                                  ${
                                    this.state.horoscopeData.non_luminaries[
                                      val
                                    ][3]
                                  }"`}
                                      </td>

                                      <td>
                                        {calculateHouseNumber(
                                          LuminaryRashiNum(
                                            this.state.horoscopeData
                                              .non_luminaries,
                                            val
                                          ),
                                          this.state.horoscopeData.natal_chart
                                            .list_of_houses[0].rashi_num
                                        )}
                                      </td>
                                    </tr>
                                  ) : (
                                    <></>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-md-5">
                          <NatalChartLuminary
                            data={this.ModifyToChartDataLuminary(
                              this.state.horoscopeData.non_luminaries,
                              t
                            )}
                            w="450"
                            h="550"
                            xview="330"
                            yview="200"
                            className="LuminarySVG"
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Tab>
                  <Tab eventKey={5} title={t("Special Ascendants")}>
                    <center className="row">
                      <h2 className="title text-right col-md-7 col-12 pt-2 pl-5">
                        {t("Special Ascendants")}
                      </h2>
                    </center>
                    {typeof this.state.horoscopeData.special_ascendants !==
                    "undefined" ? (
                      <div className="row">
                        <div className="col-md-7">
                          <Table responsive="xs" className="content-table-pp">
                            <thead>
                              <tr>
                                <th style={{ width: "250px" }}>
                                  {t("Special Ascendant")}
                                </th>
                                <th style={{ width: "250px" }}>
                                  {t("Position")}
                                </th>

                                <th style={{ width: "250px" }}>{t("House")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(
                                this.state.horoscopeData.special_ascendants
                              ).map((val, i) => {
                                return typeof this.state.horoscopeData
                                  .special_ascendants[val] === "object" ? (
                                  <tr key={val + i}>
                                    <td>
                                      {t(modifySpecialAscendantsName(val))}
                                    </td>
                                    <td>
                                      <img
                                        className="nakshatra-images"
                                        alt={this.LuminaryRashiNameImage(
                                          this.state.horoscopeData
                                            .special_ascendants,
                                          val
                                        )}
                                        src={`/assets/Zodiac/${this.LuminaryRashiNameImage(
                                          this.state.horoscopeData
                                            .special_ascendants,
                                          val
                                        )}.svg`}
                                      ></img>{" "}
                                      {`${
                                        this.state.horoscopeData
                                          .special_ascendants[val][1]
                                      }° ${
                                        localStorage.getItem(
                                          "User Default Language"
                                        ) === "English"
                                          ? this.LuminaryRashiName(
                                              this.state.horoscopeData
                                                .special_ascendants,
                                              val
                                            ).substring(0, 3)
                                          : this.LuminaryRashiName(
                                              this.state.horoscopeData
                                                .special_ascendants,
                                              val
                                            )
                                      } ${
                                        this.state.horoscopeData
                                          .special_ascendants[val][2]
                                      }'
                                  ${
                                    this.state.horoscopeData.special_ascendants[
                                      val
                                    ][3]
                                  }"`}
                                    </td>

                                    <td>
                                      {calculateHouseNumber(
                                        LuminaryRashiNum(
                                          this.state.horoscopeData
                                            .special_ascendants,
                                          val
                                        ),
                                        this.state.horoscopeData.natal_chart
                                          .list_of_houses[0].rashi_num
                                      )}
                                    </td>
                                  </tr>
                                ) : (
                                  <> </>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-md-5">
                          <NatalChartAsc
                            data={this.ModifyToChartDataAsc(
                              this.state.horoscopeData.special_ascendants,
                              t
                            )}
                            w="450"
                            h="550"
                            xview="330"
                            yview="200"
                            className="AscSVG"
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Tab>
                  <Tab eventKey={6} title={t("Karkansh Chakra")}>
                    <center className="row">
                      <h2 className="title text-right col-md-7 col-12 pt-2 pl-5">
                        {t("Karkansh Chakra")}
                      </h2>
                    </center>

                    {typeof this.state.horoscopeData.karkansh_kundali !==
                      "undefined" && this.state.karkanshTableData !== null ? (
                      <div className="row">
                        <div className="col-md-7">
                          <Table responsive="xs" className="content-table-pp">
                            <thead>
                              <tr>
                                <th style={{ width: "250px" }}>{t("Karak")}</th>
                                <th style={{ width: "250px" }}>
                                  {t("Position")}
                                </th>

                                <th style={{ width: "250px" }}>
                                  {t("Planet")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.karkanshTableData.map((val, i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{t(KarakMapper[val["Karak"]])}</td>
                                      <td>
                                        <img
                                          className="nakshatra-images"
                                          alt={val["Position"]}
                                          src={`/assets/Zodiac/${
                                            ImageMapper[
                                              val["Position"].split(" ")[1]
                                            ]
                                          }.svg`}
                                        ></img>
                                        {val["Position"]}
                                      </td>
                                      <td>
                                        <img
                                          className="PpImages"
                                          alt={val["Planet"].slice(0, 3)}
                                          src={extract_image_link(
                                            val["Planet"]
                                          )}
                                        ></img>
                                        {t(val["Planet"])}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                          </Table>
                        </div>
                        <div className="col-md-5">
                          <NatalChartsD1
                            t={t}
                            Chartname={t("Karkansh Kundali")}
                            data={
                              this.state.horoscopeData.karkansh_kundali
                                .list_of_houses
                            }
                            className="svg6"
                            id="container6"
                            Asc={t("Asc")}
                            w="450"
                            h="550"
                            xview="330"
                            yview="200"
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Tab>

                  <Tab eventKey={7} title={t("Galactic View")}>
                    <center className="row">
                      <h2 className="title text-right col-md-7 col-12 pt-2 pl-5">
                        {t("Galactic View")}
                      </h2>
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div class="svg_img">{this.getsvg()}</div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="zoom_container">
                              <div className="rotate">{this.getsvg()}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </center>
                  </Tab>

                  <Tab eventKey={8} title={t("Pushkar Navamsha")}>
                    <center className="row">
                      <h2 className="title text-right col-md-7 col-12 pt-2 pl-5">
                        {t("Pushkar Navamsha")}
                      </h2>
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div class="svg_img">
                              {this.getPushkarNavamshaSvg()}
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="zoom_container">
                              <div className="rotate">
                                {this.getPushkarNavamshaSvg()}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </center>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"planetPositions"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
        </>
      );
    }
  }
}
export default withTranslation()(PlanetPositions);
