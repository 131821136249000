import React, { Component } from "react";
import * as d3 from "d3";

import { GlobalContext } from "../../../mycontext";
import "../DivisionalCharts/Natal2.css";

class NatalChart extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.d3_Chart_ref = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    this.draw_chart_layout();
    this.rashi_placement();
      this.planet_positions();
  }

  componentDidUpdate() {
   
    d3.select(`.${this.props.className}`).remove();
    this.draw_chart_layout();
    this.rashi_placement();
    this.planet_positions();
  }

  rashi_placement() {
    var container2 = d3.select(`#${this.props.id}`);
  
    container2
      .selectAll(".rashiText")
      .data(this.props.data)
      .enter()
      .append("text")
      .attr("class", "rashiClass")
      .attr("transform", function (d, i) {
        var house1_x = 540;
        var house1_y = 400;
        if (i === 0) {
          return "translate(" + house1_x + "," + house1_y + ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 100) + "," + (house1_y - 100) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 120) + "," + (house1_y - 80) + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 20) + "," + (house1_y + 18) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 120) + "," + (house1_y + 120) + ")";
        } else if (i === 5) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 140) + ")";
        } else if (i === 6) {
          return "translate(" + house1_x + "," + (house1_y + 40) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 140) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 120) + "," + (house1_y + 120) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 20) + "," + (house1_y + 18) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 120) + "," + (house1_y - 80) + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 100) + "," + (house1_y - 100) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .text(function (d, i) {
        return d.rashi_num;
      });
  }

  planet_positions = () => {
    
    var planet_container = d3.select(`#${this.props.id}`);
    var house1_x = 540;
    var house1_y = 320;
   
    planet_container
      .append("text")
      .text(this.props.Asc)
      .attr("dy", house1_y)
      .attr("dx", house1_x - 10)
      .attr("font-size", 16)
      .attr("font-family", "monospace")
      .attr("fill", "#c0c0c0");

    
    // Define the div for the tooltip
    var div = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
    planet_container
      .selectAll(".planetText1")
      .data(this.props.data)
      .enter()
      .append("text")
      .attr("class", "planetClass1")
      .on("mouseover", function (d) {
        if (typeof d.list_of_planets[0] !== "undefined") {
          console.log(d.list_of_planets[0])
          div.transition().style("opacity", 0.9).style("width", "auto");
          div
            .html(d.list_of_planets[0])
            .style("left", d3.event.pageX - 20 + "px")
            .style("top", d3.event.pageY + 20 + "px");
        }
      })
      .on("mouseout", function (d) {
        div
          .transition()
          .duration(200)
          .style("opacity", 0)
          .style("width", "auto");
      })

      // first planet
      .attr("transform", function (d, i) {
        if (i === 0) {
          return "translate(" + house1_x + "," + (house1_y - 60) + ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 100) + "," + (house1_y - 70) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 178) + "," + house1_y + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 100) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 178) + "," + (house1_y + 200) + ")";
        } else if (i === 5) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 248) + ")";
        } else if (i === 6) {
          return "translate(" + house1_x + "," + (house1_y + 200) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 252) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 178) + "," + (house1_y + 200) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 100) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 180) + "," + house1_y + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 100) + "," + (house1_y - 70) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .attr("style", function (d, i) {
        if (d.list_of_planets[0] === "Ma") {
          return "fill:#ff0000";
        } else if (d.list_of_planets[0] === "मं") {
          return "fill:#ff0000";
        } else if (d.list_of_planets[0] === "Mo") {
          return "fill:#00b7eb";
        } else if (d.list_of_planets[0] === "चं") {
          return "fill:#00b7eb";
        } else if (d.list_of_planets[0] === "Sa") {
          return "fill:#0247fe";
        } else if (d.list_of_planets[0] === "श") {
          return "fill:#0247fe";
        } else if (d.list_of_planets[0] === "Su") {
          return "fill:#ff6347";
        } else if (d.list_of_planets[0] === "सू") {
          return "fill:#ff6347";
        } else if (d.list_of_planets[0] === "Me") {
          return "fill:#009e00";
        } else if (d.list_of_planets[0] === "बु") {
          return "fill:#009e00";
        } else if (d.list_of_planets[0] === "Ju") {
          return "fill:#ff8243";
        } else if (d.list_of_planets[0] === "गु") {
          return "fill:#ff8243";
        } else if (d.list_of_planets[0] === "Ke") {
          return "fill:#a938ff";
        } else if (d.list_of_planets[0] === "के") {
          return "fill:#a938ff";
        } else if (d.list_of_planets[0] === "Ve") {
          return "fill:#ff1dce";
        } else if (d.list_of_planets[0] === "शु") {
          return "fill:#ff1dce";
        } else if (d.list_of_planets[0] === "Ra") {
          return "fill:#696969";
        } else if (d.list_of_planets[0] === "रा") {
          return "fill:#696969";
        }
      })

      .text(function (d, i) {
        if (typeof d.list_of_planets[0] !== "undefined") {
          let language = localStorage.getItem("UserDefaultLanguage");
          if (language.length > 0) {
            if (language === "Hindi") {
              return d.list_of_planets[0];
            } else {
              console.log(d.list_of_planets[0])
              return d.list_of_planets[0].substring(0, 2);
            }
          }
        }
      });

    // second planet placement
    
    planet_container
      .selectAll(".planetText2")
      .data(this.props.data)
      .enter()
      .append("text")
      .attr("class", "planetClass2")
      .on("mouseover", function (d) {
        if (typeof d.list_of_planets[1] !== "undefined") {
          div.transition().style("opacity", 0.9).style("width", "auto");
          div
            .html(d.list_of_planets[1])
            .style("left", d3.event.pageX - 20 + "px")
            .style("top", d3.event.pageY + 20 + "px");
        }
      })
      .on("mouseout", function (d) {
        div
          .transition()
          .duration(200)
          .style("opacity", 0)
          .style("width", "auto");
      })
      .attr("transform", function (d, i) {
        
        if (i === 0) {
          return "translate(" + (house1_x - 63) + "," + house1_y + ")";
        } else if (i === 1) {
          
          return "translate(" + (house1_x - 120) + "," + (house1_y - 50) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 178) + "," + (house1_y - 45) + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 80) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 179) + "," + (house1_y + 146) + ")";
        } else if (i === 5) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 285) + ")";
        } else if (i === 6) {
          return "translate(" + house1_x + "," + (house1_y + 240) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 285) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 180) + "," + (house1_y + 235) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 80) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 178) + "," + (house1_y - 50) + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 100) + "," + (house1_y - 90) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .attr("style", function (d, i) {
        
        if (d.list_of_planets[1] === "Ma") {
          return "fill:#ff0000";
        } else if (d.list_of_planets[1] === "मं") {
          return "fill:#ff0000";
        } else if (d.list_of_planets[1] === "Mo") {
          return "fill:#00b7eb";
        } else if (d.list_of_planets[1] === "चं") {
          return "fill:#00b7eb";
        } else if (d.list_of_planets[1] === "Sa") {
          return "fill:#0247fe";
        } else if (d.list_of_planets[1] === "श") {
          return "fill:#0247fe";
        } else if (d.list_of_planets[1] === "Su") {
          return "fill:#ff6347";
        } else if (d.list_of_planets[1] === "सू") {
          return "fill:#ff6347";
        } else if (d.list_of_planets[1] === "Me") {
          return "fill:#009e00";
        } else if (d.list_of_planets[1] === "बु") {
          return "fill:#009e00";
        } else if (d.list_of_planets[1] === "Ju") {
          return "fill:#ff8243";
        } else if (d.list_of_planets[1] === "गु") {
          return "fill:#ff8243";
        } else if (d.list_of_planets[1] === "Ke") {
          return "fill:#a938ff";
        } else if (d.list_of_planets[1] === "के") {
          return "fill:#a938ff";
        } else if (d.list_of_planets[1] === "Ve") {
          return "fill:#ff1dce";
        } else if (d.list_of_planets[1] === "शु") {
          return "fill:#ff1dce";
        } else if (d.list_of_planets[1] === "Ra") {
          return "fill:#696969";
        } else if (d.list_of_planets[1] === "रा") {
          return "fill:#696969";
        }
      })
      .text(function (d, i) {
        if (typeof d.list_of_planets[1] !== "undefined") {
          let language = localStorage.getItem("UserDefaultLanguage");
          if (language.length > 0) {
            if (language === "Hindi") {
              
              return d.list_of_planets[1];
            } else {
               console.log(d.list_of_planets[1])
              return d.list_of_planets[1].substring(0, 2);
            }
          }
        }
      });

    // Third Planet
    planet_container
      .selectAll(".planetText3")
      .data(this.props.data)
      .enter()
      .append("text")
      .attr("class", "planetClass3")
      .on("mouseover", function (d) {
        if (typeof d.list_of_planets[2] !== "undefined") {
          div
            .transition()
            .duration(200)
            .style("opacity", 0.9)
            .style("width", "auto");
          div
            .html(d.list_of_planets[2])
            .style("left", d3.event.pageX - 20 + "px")
            .style("top", d3.event.pageY + 20 + "px");
        }
      })
      .on("mouseout", function (d) {
        div
          .transition()
          .duration(200)
          .style("opacity", 0)
          .style("width", "auto");
      })
      .attr("transform", function (d, i) {
        
        
        if (i === 0) {
          return "translate(" + (house1_x + 50) + "," + house1_y + ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 45) + "," + (house1_y - 90) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 178) + "," + (house1_y-25) + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 140) + "," + (house1_y + 100) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 178) + "," + (house1_y + 163) + ")";
        } else if (i === 5) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 233) + ")";
        } else if (i === 6) {
          return "translate(" + house1_x + "," + (house1_y + 175) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 235) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 180) + "," + (house1_y + 180) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 145) + "," + (house1_y + 100) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 178) + "," + (house1_y - 30) + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 120) + "," + (house1_y - 50) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .attr("style", function (d, i) {
        
        if (d.list_of_planets[2] === "Ma") {
          return "fill:#ff0000";
        } else if (d.list_of_planets[2] === "मं") {
          return "fill:#ff0000";
        } else if (d.list_of_planets[2] === "Mo") {
          return "fill:#00b7eb";
        } else if (d.list_of_planets[2] === "चं") {
          return "fill:#00b7eb";
        } else if (d.list_of_planets[2] === "Sa") {
          return "fill:#0247fe";
        } else if (d.list_of_planets[2] === "श") {
          return "fill:#0247fe";
        } else if (d.list_of_planets[2] === "Su") {
          return "fill:#ff6347";
        } else if (d.list_of_planets[2] === "सू") {
          return "fill:#ff6347";
        } else if (d.list_of_planets[2] === "Me") {
          return "fill:#009e00";
        } else if (d.list_of_planets[2] === "बु") {
          return "fill:#009e00";
        } else if (d.list_of_planets[2] === "Ju") {
          return "fill:#ff8243";
        } else if (d.list_of_planets[2] === "गु") {
          return "fill:#ff8243";
        } else if (d.list_of_planets[2] === "Ke") {
          return "fill:#a938ff";
        } else if (d.list_of_planets[2] === "के") {
          return "fill:#a938ff";
        } else if (d.list_of_planets[2] === "Ve") {
          return "fill:#ff1dce";
        } else if (d.list_of_planets[2] === "शु") {
          return "fill:#ff1dce";
        } else if (d.list_of_planets[2] === "Ra") {
          return "fill:#696969";
        } else if (d.list_of_planets[2] === "रा") {
          return "fill:#696969";
        }
      })
      .text(function (d, i) {
        
        if (typeof d.list_of_planets[2] !== "undefined") {
          let language = localStorage.getItem("UserDefaultLanguage");
          if (language.length > 0) {
            if (language === "Hindi") {
              return d.list_of_planets[2];
            } else {
              console.log(d.list_of_planets[2])
              return d.list_of_planets[2].substring(0, 2);
            }
          }
        }
      });

    // fourth Planet
    planet_container
      .selectAll(".planetText4")
      .data(this.props.data)
      .enter()
      .append("text")
      .attr("class", "planetClass4")
      .on("mouseover", function (d) {
        if (typeof d.list_of_planets[3] !== "undefined") {
          div
            .transition()
            .duration(200)
            .style("opacity", 0.9)
            .style("width", "auto");
          div
            .html(d.list_of_planets[3])
            .style("left", d3.event.pageX - 20 + "px")
            .style("top", d3.event.pageY + 20 + "px");
        }
      })
      .on("mouseout", function (d) {
        div
          .transition()
          .duration(200)
          .style("opacity", 0)
          .style("width", "auto");
      })
      .attr("transform", function (d, i) {
        
        if (i === 0) {
          return "translate(" + house1_x + "," + (house1_y + 50) + ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 150) + "," + (house1_y - 90) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 179) + "," + (house1_y + 51) + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 160) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 179) + "," + (house1_y + 250) + ")";
        } else if (i === 5) {
          return "translate(" + (house1_x - 145) + "," + (house1_y + 285) + ")";
        } else if (i === 6) {
          return "translate(" + house1_x + "," + (house1_y + 150) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 145) + "," + (house1_y + 285) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 180) + "," + (house1_y + 150) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 140) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 178) + "," + (house1_y + 51) + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 150) + "," + (house1_y - 90) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .attr("style", function (d, i) {
        if (d.list_of_planets[3] === "Ma") {
          return "fill:#ff0000";
        } else if (d.list_of_planets[3] === "मं") {
          return "fill:#ff0000";
        } else if (d.list_of_planets[3] === "Mo") {
          return "fill:#00b7eb";
        } else if (d.list_of_planets[3] === "चं") {
          return "fill:#00b7eb";
        } else if (d.list_of_planets[3] === "Sa") {
          return "fill:#0247fe";
        } else if (d.list_of_planets[3] === "श") {
          return "fill:#0247fe";
        } else if (d.list_of_planets[3] === "Su") {
          return "fill:#ff6347";
        } else if (d.list_of_planets[3] === "सू") {
          return "fill:#ff6347";
        } else if (d.list_of_planets[3] === "Me") {
          return "fill:#009e00";
        } else if (d.list_of_planets[3] === "बु") {
          return "fill:#009e00";
        } else if (d.list_of_planets[3] === "Ju") {
          return "fill:#ff8243";
        } else if (d.list_of_planets[3] === "गु") {
          return "fill:#ff8243";
        } else if (d.list_of_planets[3] === "Ke") {
          return "fill:#a938ff";
        } else if (d.list_of_planets[3] === "के") {
          return "fill:#a938ff";
        } else if (d.list_of_planets[3] === "Ve") {
          return "fill:#ff1dce";
        } else if (d.list_of_planets[3] === "शु") {
          return "fill:#ff1dce";
        } else if (d.list_of_planets[3] === "Ra") {
          return "fill:#696969";
        } else if (d.list_of_planets[3] === "रा") {
          return "fill:#696969";
        }
      })
      .text(function (d, i) {
        if (typeof d.list_of_planets[3] !== "undefined") {
          let language = localStorage.getItem("UserDefaultLanguage");
          if (language.length > 0) {
            if (language === "Hindi") {
              return d.list_of_planets[3];
            } else {
              console.log(d.list_of_planets[3])
              return d.list_of_planets[3].substring(0, 2);
            }
          }
        }
      });

    // fifth Planet

    planet_container
      .selectAll(".planetText5")
      .data(this.props.data)
      .enter()
      .append("text")
      .attr("class", "planetClass5")
      .on("mouseover", function (d) {
        if (typeof d.list_of_planets[4] !== "undefined") {
          div
            .transition()
            .duration(200)
            .style("opacity", 0.9)
            .style("width", "auto");
          div
            .html(d.list_of_planets[4])
            .style("left", d3.event.pageX - 20 + "px")
            .style("top", d3.event.pageY + 20 + "px");
        }
      })
      .on("mouseout", function (d) {
        div
          .transition()
          .duration(200)
          .style("opacity", 0)
          .style("width", "auto");
      })
      .attr("transform", function (d, i) {
        if (i === 0) {
          return "translate(" + house1_x + "," + (house1_y - 30) + ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 60) + "," + (house1_y - 70) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 140) + "," + (house1_y -12 ) + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 54) + "," + (house1_y + 100) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 178) + "," + (house1_y + 180) + ")";
        } else if (i === 5) {
          return "translate(" + (house1_x - 55) + "," + (house1_y + 285) + ")";
        } else if (i === 6) {
          return "translate(" + (house1_x - 50) + "," + (house1_y + 200) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 55) + "," + (house1_y + 285) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 180) + "," + (house1_y + 250) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 54) + "," + (house1_y + 100) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 145) + "," + (house1_y-13) + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 60) + "," + (house1_y - 70) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .attr("style", function (d, i) {
        
        if (d.list_of_planets[4] === "Ma") {
          return "fill:#ff0000";
        } else if (d.list_of_planets[4] === "मं") {
          return "fill:#ff0000";
        } else if (d.list_of_planets[4] === "Mo") {
          return "fill:#00b7eb";
        } else if (d.list_of_planets[4] === "चं") {
          return "fill:#00b7eb";
        } else if (d.list_of_planets[4] === "Sa") {
          return "fill:#0247fe";
        } else if (d.list_of_planets[4] === "श") {
          return "fill:#0247fe";
        } else if (d.list_of_planets[4] === "Su") {
          return "fill:#ff6347";
        } else if (d.list_of_planets[4] === "सू") {
          return "fill:#ff6347";
        } else if (d.list_of_planets[4] === "Me") {
          return "fill:#009e00";
        } else if (d.list_of_planets[4] === "बु") {
          return "fill:#009e00";
        } else if (d.list_of_planets[4] === "Ju") {
          return "fill:#ff8243";
        } else if (d.list_of_planets[4] === "गु") {
          return "fill:#ff8243";
        } else if (d.list_of_planets[4] === "Ke") {
          return "fill:#a938ff";
        } else if (d.list_of_planets[4] === "के") {
          return "fill:#a938ff";
        } else if (d.list_of_planets[4] === "Ve") {
          return "fill:#ff1dce";
        } else if (d.list_of_planets[4] === "शु") {
          return "fill:#ff1dce";
        } else if (d.list_of_planets[4] === "Ra") {
          return "fill:#696969";
        } else if (d.list_of_planets[4] === "रा") {
          return "fill:#696969";
        }
      })
      .text(function (d, i) {
        if (typeof d.list_of_planets[4] !== "undefined") {
          let language = localStorage.getItem("UserDefaultLanguage");
          if (language.length > 0) {
            if (language === "Hindi") {
              return d.list_of_planets[4];
            } else {
              console.log(d.list_of_planets[4])
              return d.list_of_planets[4].substring(0, 2);
            }
          }
        }
      });



      //sixth planet
      
    planet_container
    .selectAll(".planetText5")
    .data(this.props.data)
    .enter()
    .append("text")
    .attr("class", "planetClass6")
    .on("mouseover", function (d) {
      if (typeof d.list_of_planets[5] !== "undefined") {
        div
          .transition()
          .duration(200)
          .style("opacity", 0.9)
          .style("width", "auto");
        div
          .html(d.list_of_planets[5])
          .style("left", d3.event.pageX - 20 + "px")
          .style("top", d3.event.pageY + 20 + "px");
      }
    })
    .on("mouseout", function (d) {
      div
        .transition()
        .duration(200)
        .style("opacity", 0)
        .style("width", "auto");
    })
    .attr("transform", function (d, i) {
        
        
        if (i === 0) {
          return "translate(" + (house1_x + 43) + "," + (house1_y - 30)+ ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 145) + "," + (house1_y - 70) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 180) + "," + (house1_y + 36) + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 60) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 180) + "," + (house1_y + 235) + ")";
        } else if (i === 5) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 265) + ")";
        } else if (i === 6) {
          return "translate(" + (house1_x) + "," + (house1_y + 260) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 60) + "," + (house1_y + 270) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 145) + "," + (house1_y + 215) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 40) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 178) + "," + (house1_y + 36) + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 143) + "," + (house1_y - 70) + ")";
        } else {
          return "translate(530,500)";
        }
      })
    .attr("text-anchor", "middle")
    // .attr("xlink:href", function (d, i) {
    //     return "#SunSign_Arc_" + i;
    // })
    .attr("style", function (d, i) {
      
      if (d.list_of_planets[5] === "Ma") {
        return "fill:#ff0000";
      } else if (d.list_of_planets[5] === "मं") {
        return "fill:#ff0000";
      } else if (d.list_of_planets[5] === "Mo") {
        return "fill:#00b7eb";
      } else if (d.list_of_planets[5] === "चं") {
        return "fill:#00b7eb";
      } else if (d.list_of_planets[5] === "Sa") {
        return "fill:#0247fe";
      } else if (d.list_of_planets[5] === "श") {
        return "fill:#0247fe";
      } else if (d.list_of_planets[5] === "Su") {
        return "fill:#ff6347";
      } else if (d.list_of_planets[5] === "सू") {
        return "fill:#ff6347";
      } else if (d.list_of_planets[5] === "Me") {
        return "fill:#009e00";
      } else if (d.list_of_planets[5] === "बु") {
        return "fill:#009e00";
      } else if (d.list_of_planets[5] === "Ju") {
        return "fill:#ff8243";
      } else if (d.list_of_planets[5] === "गु") {
        return "fill:#ff8243";
      } else if (d.list_of_planets[5] === "Ke") {
        return "fill:#a938ff";
      } else if (d.list_of_planets[5] === "के") {
        return "fill:#a938ff";
      } else if (d.list_of_planets[5] === "Ve") {
        return "fill:#ff1dce";
      } else if (d.list_of_planets[5] === "शु") {
        return "fill:#ff1dce";
      } else if (d.list_of_planets[5] === "Ra") {
        return "fill:#696969";
      } else if (d.list_of_planets[5] === "रा") {
        return "fill:#696969";
      }
    })
    .text(function (d, i) {
      if (typeof d.list_of_planets[5] !== "undefined") {
        let language = localStorage.getItem("UserDefaultLanguage");
        if (language.length > 0) {
          if (language === "Hindi") {
            return d.list_of_planets[5];
          } else {
            console.log(d.list_of_planets[5])
            return d.list_of_planets[5].substring(0, 2);
          }
        }
      }
    });

     //seventh planet
   
   planet_container
   .selectAll(".planetText5")
   .data(this.props.data)
   .enter()
   .append("text")
   .attr("class", "planetClass7")
   .on("mouseover", function (d) {
     if (typeof d.list_of_planets[6] !== "undefined") {
       div
         .transition()
         .duration(200)
         .style("opacity", 0.9)
         .style("width", "auto");
       div
         .html(d.list_of_planets[6])
         .style("left", d3.event.pageX - 20 + "px")
         .style("top", d3.event.pageY + 20 + "px");
     }
   })
   .on("mouseout", function (d) {
     div
       .transition()
       .duration(200)
       .style("opacity", 0)
       .style("width", "auto");
   })
   .attr("transform", function (d, i) {
       
       
       if (i === 0) {
         return "translate(" + (house1_x - 40) + "," + (house1_y - 30)+ ")";
       } else if (i === 1) {
         return "translate(" + (house1_x - 77) + "," + (house1_y - 50) + ")";
       } else if (i === 2) {
         return "translate(" + (house1_x - 140) + "," + (house1_y + 12) + ")";
       } else if (i === 3) {
         return "translate(" + (house1_x - 100) + "," + (house1_y + 140) + ")";
       } else if (i === 4) {
         return "translate(" + (house1_x - 180) + "," + (house1_y + 220) + ")";
       } else if (i === 5) {
         return "translate(" + (house1_x - 138) + "," + (house1_y + 265) + ")";
       } else if (i === 6) {
         return "translate(" + (house1_x ) + "," + (house1_y + 220) + ")";
       } else if (i === 7) {
         return "translate(" + (house1_x + 100) + "," + (house1_y + 270) + ")";
       } else if (i === 8) {
         return "translate(" + (house1_x + 180) + "," + (house1_y + 165) + ")";
       } else if (i === 9) {
         return "translate(" + (house1_x + 100) + "," + (house1_y + 60) + ")";
       } else if (i === 10) {
         return "translate(" + (house1_x + 180) + "," + (house1_y + 20) + ")";
       } else if (i === 11) {
         return "translate(" + (house1_x + 77) + "," + (house1_y - 50) + ")";
       } else {
         return "translate(530,500)";
       }
     })
   .attr("text-anchor", "middle")
   // .attr("xlink:href", function (d, i) {
   //     return "#SunSign_Arc_" + i;
   // })
   .attr("style", function (d, i) {
     
     if (d.list_of_planets[6] === "Ma") {
       return "fill:#ff0000";
     } else if (d.list_of_planets[6] === "मं") {
       return "fill:#ff0000";
     } else if (d.list_of_planets[6] === "Mo") {
       return "fill:#00b7eb";
     } else if (d.list_of_planets[6] === "चं") {
       return "fill:#00b7eb";
     } else if (d.list_of_planets[6] === "Sa") {
       return "fill:#0247fe";
     } else if (d.list_of_planets[6] === "श") {
       return "fill:#0247fe";
     } else if (d.list_of_planets[6] === "Su") {
       return "fill:#ff6347";
     } else if (d.list_of_planets[6] === "सू") {
       return "fill:#ff6347";
     } else if (d.list_of_planets[6] === "Me") {
       return "fill:#009e00";
     } else if (d.list_of_planets[6] === "बु") {
       return "fill:#009e00";
     } else if (d.list_of_planets[6] === "Ju") {
       return "fill:#ff8243";
     } else if (d.list_of_planets[6] === "गु") {
       return "fill:#ff8243";
     } else if (d.list_of_planets[6] === "Ke") {
       return "fill:#a938ff";
     } else if (d.list_of_planets[6] === "के") {
       return "fill:#a938ff";
     } else if (d.list_of_planets[6] === "Ve") {
       return "fill:#ff1dce";
     } else if (d.list_of_planets[6] === "शु") {
       return "fill:#ff1dce";
     } else if (d.list_of_planets[6] === "Ra") {
       return "fill:#696969";
     } else if (d.list_of_planets[6] === "रा") {
       return "fill:#696969";
     }
   })
   .text(function (d, i) {
     if (typeof d.list_of_planets[6] !== "undefined") {
       let language = localStorage.getItem("UserDefaultLanguage");
       if (language.length > 0) {
         if (language === "Hindi") {
           return d.list_of_planets[6];
         } else {
           console.log(d.list_of_planets[6])
           return d.list_of_planets[6].substring(0, 2);
         }
       }
     }
   });


//eight planet

planet_container
   .selectAll(".planetText8")
   .data(this.props.data)
   .enter()
   .append("text")
   .attr("class", "planetClass8")
   .on("mouseover", function (d) {
     if (typeof d.list_of_planets[7] !== "undefined") {
       div
         .transition()
         .duration(200)
         .style("opacity", 0.9)
         .style("width", "auto");
       div
         .html(d.list_of_planets[7])
         .style("left", d3.event.pageX - 20 + "px")
         .style("top", d3.event.pageY + 20 + "px");
     }
   })
   .on("mouseout", function (d) {
     div
       .transition()
       .duration(200)
       .style("opacity", 0)
       .style("width", "auto");
   })
   .attr("transform", function (d, i) {
       if (i === 0) {
         return "translate(" + (house1_x + 0) + "," + (house1_y + 30)+ ")";
       } else if (i === 1) {
         return "translate(" + (house1_x - 100) + "," + (house1_y - 90) + ")";
       } else if (i === 2) {
         return "translate(" + (house1_x - 179) + "," + (house1_y + 22) + ")";
       } else if (i === 3) {
         return "translate(" + (house1_x - 100) + "," + (house1_y + 120) + ")";
       } else if (i === 4) {
         return "translate(" + (house1_x - 142) + "," + (house1_y + 210) + ")";
       } else if (i === 5) {
         return "translate(" + (house1_x - 60) + "," + (house1_y + 265) + ")";
       } else if (i === 6) {
         return "translate(" + (house1_x + 50) + "," + (house1_y + 200) + ")";
       } else if (i === 7) {
         return "translate(" + (house1_x + 145) + "," + (house1_y + 270) + ")";
       } else if (i === 8) {
         return "translate(" + (house1_x + 145) + "," + (house1_y + 185) + ")";
       } else if (i === 9) {
         return "translate(" + (house1_x + 100) + "," + (house1_y + 120) + ")";
       } else if (i === 10) {
         return "translate(" + (house1_x + 150) + "," + (house1_y + 10) + ")";
       } else if (i === 11) {
         return "translate(" + (house1_x + 50) + "," + (house1_y - 90) + ")";
       } else {
         return "translate(530,500)";
       }
     })
   .attr("text-anchor", "middle")
   // .attr("xlink:href", function (d, i) {
   //     return "#SunSign_Arc_" + i;
   // })
   .attr("style", function (d, i) {
     
     if (d.list_of_planets[7] === "Ma") {
       return "fill:#ff0000";
     } else if (d.list_of_planets[7] === "मं") {
       return "fill:#ff0000";
     } else if (d.list_of_planets[7] === "Mo") {
       return "fill:#00b7eb";
     } else if (d.list_of_planets[7] === "चं") {
       return "fill:#00b7eb";
     } else if (d.list_of_planets[7] === "Sa") {
       return "fill:#0247fe";
     } else if (d.list_of_planets[7] === "श") {
       return "fill:#0247fe";
     } else if (d.list_of_planets[7] === "Su") {
       return "fill:#ff6347";
     } else if (d.list_of_planets[7] === "सू") {
       return "fill:#ff6347";
     } else if (d.list_of_planets[7] === "Me") {
       return "fill:#009e00";
     } else if (d.list_of_planets[7] === "बु") {
       return "fill:#009e00";
     } else if (d.list_of_planets[7] === "Ju") {
       return "fill:#ff8243";
     } else if (d.list_of_planets[7] === "गु") {
       return "fill:#ff8243";
     } else if (d.list_of_planets[7] === "Ke") {
       return "fill:#a938ff";
     } else if (d.list_of_planets[7] === "के") {
       return "fill:#a938ff";
     } else if (d.list_of_planets[7] === "Ve") {
       return "fill:#ff1dce";
     } else if (d.list_of_planets[7] === "शु") {
       return "fill:#ff1dce";
     } else if (d.list_of_planets[7] === "Ra") {
       return "fill:#696969";
     } else if (d.list_of_planets[7] === "रा") {
       return "fill:#696969";
     }
   })
   .text(function (d, i) {
     if (typeof d.list_of_planets[7] !== "undefined") {
       let language = localStorage.getItem("UserDefaultLanguage");
       if (language.length > 0) {
         if (language === "Hindi") {
           return d.list_of_planets[7];
         } else {
           console.log(d.list_of_planets[7])
           return d.list_of_planets[7].substring(0, 2);
         }
       }
     }
   });
  };

  
  draw_chart_layout() {
    var json = [
      {
        chart: "D1",
        Asc: 11,
        rashi: {
          11: ["Su", "Ma", "Sa"],
          12: ["Me"],
          1: ["Mo"],
          2: ["Ra", "Ju"],
          3: [],
          6: [],
          7: [],
          8: [],
          9: [],
          10: ["Ke"],
          11: [],
          12: ["Ve"],
        },
      },
    ];
    var w = 500;
    var h = 630;
    var x = 1080 / 2;
    var y = 630 / 2;
    var xview = 330;
    var yview = 210;
    var svg = d3
      .select(this.d3_Chart_ref.current)
      .append("svg")
      // .attr("width", w)
      // .attr("height", h)
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("class", this.props.className)
      .attr("viewBox", xview + " " + yview + " " + w + " " + h + "");

    var container = svg.append("g").attr("id", this.props.id);

    var rectangle = container
      .append("rect")
      .style("stroke", "grey")
      .style("stroke-width", "2px")
      .style("fill", "none")
      .attr("x", x - 200)
      .attr("y", y - 100)
      .attr("width", 400)
      .attr("height", 400);

    var rectangle2 = container
      .append("rect")
      .style("stroke", "grey")
      .style("stroke-width", "2px")
      .style("fill", "none")
      .attr("x", x - 5)
      .attr("y", y - 545)
      .attr("width", 281)
      .attr("height", 281)
      .attr("transform", "rotate(45)");

    var line1 = container
      .append("line")
      .attr("x1", x + 200)
      .attr("x2", x - 200)
      .attr("y1", y + 300)
      .attr("y2", y - 100)
      .style("stroke", "grey")
      .style("stroke-width", "2px");

    var line2 = container
      .append("line")
      .attr("x1", x + 200)
      .attr("x2", x - 200)
      .attr("y1", y - 100)
      .attr("y2", y + 300)
      .style("stroke", "grey")
      .style("stroke-width", "2px");

    var house1_x = 540;
    var house1_y = 400;
    // planet group
    // container
    //   .append("text")
    //   .text("Su")
    //   .attr("dy", house1_y)
    //   .attr("dx", house1_x)
    //   .attr("font-size", 15)
    //   .attr("font-family", "monospace")
    //   .attr("fill", "white");

    var text = svg.append("g").attr("id", "text_");

    //Append the month names to each slice
    // container
    //   .selectAll(".monthT")
    //   .data(data)
    //   .enter()
    //   .append("text")
    //   .attr("class", "monT")
    //   .attr("x", house1_x) //Move the text from the start angle of the arc
    //   .attr("y", house1_y) //Move the text down
    //   .attr("text-anchor", "middle")
    //   // .attr("xlink:href", function (d, i) {
    //   //     return "#SunSign_Arc_" + i;
    //   // })
    //   .text(function (d, i) {
    //     return d.planets;
    //   });
  }

  render() {
    return (
      <>
        <div className="col-lg-6 ">
          <div ref={this.d3_Chart_ref}>
            <span className="chartname_title"> {this.props.Chartname} </span>
          </div>
        </div>
      </>
    );
  }
}

export default NatalChart;
