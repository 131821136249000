import React from "react";
import { Table } from "react-bootstrap";
import ImageMapper from "../ImageMapper.json";
export default function SnapshotTable(props) {
  // Use the state and functions returned from useTable to build your UI

  function extract_image_link(name) {
    let imglink = "/assets/Planets/";
    return imglink + ImageMapper[name] + ".png";
  }
  function extract_image_link_nakshatra(name) {
    let imglink = "/assets/Nakshatra/";
    // console.log("iconsssss", name);
    return imglink + ImageMapper[name] + ".svg";
  }

  const RashiMapper = {
    Aqu: "Aquarius",
    Ari: "Aries",
    Can: "Cancer",
    Cap: "Capricorn",
    Gem: "Gemini",
    Leo: "Leo",
    Lib: "Libra",
    Pis: "Pisces",
    Sag: "Sagittarius",
    Sco: "Scorpio",
    Tau: "Taurus",
    Vir: "Virgo",
  };

  // Render the UI for your table
  return (
    <>
      <Table responsive="xs" className="content-table-pp" id="snapshot-table">
        <thead>
          <tr>
            <th colspan="8">{props.headerString}</th>
          </tr>
          <tr>
            <th>{props.t("Planet")}</th>
            <th>{props.t("Degrees")}</th>
            <th>{props.t("Degrees in Rashi")}</th>
            <th>{props.t("Motion")}</th>
            <th>{props.t("Nakshtra")}</th>
            <th>{props.t("Pada")}</th>
            <th>{props.t("Nakshtra Lord")}</th>
            <th>{props.t("Combust")}</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map(function (val, i) {
            
            return (
              <tr key={val + i}>
                <td>
                  {val["planet_name"] !== "Ascendant" &&
                  val["planet_name"] !== "लग्न" ? (
                    <img
                      className="PpImages"
                      alt={val["planet_name"].slice(0, 3)}
                      src={extract_image_link(val["planet_name"])}
                    ></img>
                  ) : (
                    <></>
                  )}
                  {val["planet_name"]}
                </td>
                <td>{val["abs_degree"]}</td>
                <td>
                  {" "}
                  {val["r_d_m_s"] ? (
                    <img
                      className="nakshatra-images"
                      alt={val["nakshtra"]}
                      src={`/assets/Zodiac/${
                        ImageMapper[val["r_d_m_s"].split(" ")[1]]
                      }.svg`}
                    ></img>
                  ) : (
                    <></>
                  )}
                  {val["r_d_m_s"]}
                </td>
                <td>{val["motion"]}</td>
                <td>
                  {" "}
                  {val["nakshtra"] ? (
                    <img
                      className="nakshatra-images"
                      alt={val["nakshtra"]}
                      src={extract_image_link_nakshatra(val["nakshtra"])}
                    ></img>
                  ) : (
                    <></>
                  )}
                  
                  {`${val["nakshtra"]}`}
                </td>
                <td>{val["nakshtra_pada"]}</td>
                <td>
                  {val["nakshatra_lord"] !== "Ascendant" ? (
                    <img
                      className="PpImages"
                      alt={val["nakshtra_lord"].slice(0, 3)}
                      src={extract_image_link(val["nakshtra_lord"])}
                    ></img>
                  ) : (
                    <></>
                  )}
                  {val["nakshtra_lord"]}
                </td>
                <td>
                  {val["combust"] === true
                    ? props.t("Yes")
                    : val["combust"] === false
                    ? props.t("No")
                    : props.t("Never")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
